import { Box, Grid, Typography, Button } from "@mui/material";
import {
  CalendarToday,
  Assignment,
  Category,
  SquareFoot,
  Person,
} from "@mui/icons-material";
import StatusChip from "../styled/generic/StatusChip";
import { SALE_TYPE } from "../../helpers/constants";
import { Link } from "react-router-dom";
import config from "../../config/index";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ProjectUnitBooking({ bookings }) {
  const history = useHistory();
  var isDev = config?.mode == "dev";

  return (
    <Grid container spacing={2}>
      {bookings && bookings.length > 0 ? (
        bookings.map((booking) => (
          <Grid item xs={12} md={6} lg={4} key={booking._id}>
            <Box
              sx={{
                borderRadius: "8px",
                boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
                overflow: "hidden",
                transition:
                  "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                cursor: "pointer",
                "&:hover": {
                  boxShadow: "0 1px 5px 0 rgba(0,0,0,0.2)",
                },
              }}
            >
              <Box sx={{ p: 2 }}>
                <Box sx={{ display: "flex", gap: 1.5, mb: 2 }}>
                  <StatusChip
                    label={booking.status}
                    variant={booking.isDraft ? "Warning" : "Success"}
                    rounded={true}
                    size="small"
                  />
                  <StatusChip
                    label={booking.cancelled ? "Cancelled" : "Active"}
                    variant={booking.cancelled ? "Error" : "Success"}
                    rounded={true}
                    size="small"
                  />
                  <StatusChip
                    label={`Invite: ${booking.inviteCode}`}
                    variant="Info"
                    rounded={true}
                    size="small"
                  />
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CalendarToday
                      sx={{ mr: 1, color: "text.secondary", fontSize: "1rem" }}
                    />
                    <Typography variant="body2" color="text.primary">
                      Booking Date:{" "}
                      {new Date(booking.bookingDate).toLocaleDateString()}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Assignment
                      sx={{ mr: 1, color: "text.secondary", fontSize: "1rem" }}
                    />
                    <Typography variant="body2" color="text.primary">
                      Status: {booking.bookingStatus}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Category
                      sx={{ mr: 1, color: "text.secondary", fontSize: "1rem" }}
                    />
                    <Typography variant="body2" color="text.primary">
                      Type:{" "}
                      {SALE_TYPE[booking.bookingType]?.label ||
                        booking.bookingType}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SquareFoot
                      sx={{ mr: 1, color: "text.secondary", fontSize: "1rem" }}
                    />
                    <Typography variant="body2" color="text.primary">
                      Total Area: {booking.area} sq ft
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SquareFoot
                      sx={{ mr: 1, color: "text.secondary", fontSize: "1rem" }}
                    />
                    <Typography variant="body2" color="text.primary">
                      Saleable Area: {booking.saleableArea} sq ft
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Person
                      sx={{ mr: 1, color: "text.secondary", fontSize: "1rem" }}
                    />
                    <Typography variant="body2" color="text.primary">
                      Broker Involved: {booking.isBrokerInvolved ? "Yes" : "No"}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      if (isDev) {
                        history.push(`/booking/view/${booking._id}`);
                      } else if (config.mode == "prod") {
                        let link = `https://bookings.reallist.ai/booking/view/${booking._id}`;
                        window.open(link, "_self");
                      }
                    }}
                  >
                    View Details
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary" align="center">
            No bookings found for this unit
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
