import React, { useEffect, useState, useMemo } from "react";
import Dialog from "../../../styled/generic/Dialog";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import DatePicker from "../../../styled/generic/DatePicker";
import dayjs from "dayjs";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useSelector } from "react-redux";
import HorizBox from "../../../styled/generic/HorizBox";
import Select from "../../../styled/generic/Select";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, makeStyles } from "@material-ui/core";
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  IconButton,
  MenuItem,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  tooltipClasses,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Add, Delete, EditOff, Edit as EditIcon } from "@mui/icons-material";
import DocumentUploader from "../../../styled/generic/DocumentUploader";
import Api from "../../../../helpers/Api";
import { useDispatch } from "react-redux";
import DocViewer from "../../../styled/generic/DocViewer";
import LabelsDrawer from "./LabelsDrawer";
import UserPickerDropdown from "../../../styled/generic/UserPickerDropdown";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import CategoryCreateDrawer from "../../../OfferingsAndProducts/Category.Crearte.Drawer";
import Button from "../../../styled/generic/Button";
import useShareAndAssign from "../../../ResourceAccessControl/shareAndAssign";
import { updateWallet } from "../../../finance2o/accounts/paymentMethods/dwolla/api";
import { styled } from "@mui/material/styles";
import CustomAutocomplete from "../../../styled/CommonComponents/CustomAutocomplete";
import CategoryAutocomplete from "../../../OfferingsAndProducts/CategoryAutocomplete";
import CheckpointManager from "../../../OfferingsAndProducts/CheckpointManager";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import { getProgressColor } from "../../../../helpers/colorHelper";
import ProjectPicker from "../../../styled/generic/ProjectPicker";
import OrgPicker from "../../../styled/generic/OrgPicker";
import _ from "lodash";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
} from "@material-ui/core";
import BlockUnitViewer from "./BlockUnitViewer";
import NormalDrawer from "../../../styled/CommonComponents/NormalDrawer";
import {
  getSOPs,
  getChecklists,
  createChecklist,
  updateChecklist,
  addCheckpointToChecklist,
  removeCheckpointFromChecklist,
  getAllChecklists,
} from "../../../OfferingsAndProducts/Api.call";
import arrayToReducer from "../../../../helpers/arrayToReducer";
import DocPickerDrawer from "../../../doc/doc.picker.drawer";
import SchedulingInfo from './SchedulingInfo';
import moment from 'moment';
import CircularProgress from "@mui/material/CircularProgress";
import { getLinerStatuses } from "../../../../helpers/approval";
// Import statement (adjust the path as needed)

// Assume all other imports remain the same

const StyledFormBox = styled(FormBox)(({ theme }) => ({
  "& .MuiTypography-root": {
    fontSize: "0.875rem",
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: theme.spacing(1),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontSize: "0.875rem",
  },
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontSize: "0.875rem",
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const assignBtn = (
  <Button variant="outlined" startIcon={<Add />}>
    Click here to assign
  </Button>
);

function getLibraryId(library) {
  if (typeof library === "object" && library !== null) {
    return library?._id;
  } else if (typeof library === "string") {
    return library;
  }
  return null;
}

const useStyles = makeStyles((theme) => ({
  drawerBody: {
    width: "100%",
    padding: "20px",
    height: "calc(100% - 80px)",
    overflowY: "auto",
  },
  drawerBottomBtn: {
    width: "100%",
    height: "80px",
    paddingBottom: "20px",
    padding: "0px 20px",
  },
  organizationCover: {
    position: "relative",
    width: "100%",
  },
  editOrg: {
    width: "fit-content",
    position: "absolute",
    right: "1rem",
    bottom: "3rem",
  },
}));

// Helper function to format floor number (add at the top of the component or in a utils file)
const formatFloorLabel = (floorNumber) => {
  if (floorNumber < 0) {
    return `Basement ${Math.abs(floorNumber)}`;
  }
  if (floorNumber == 0) {
    return `GroundFloor`;
  }
  return `Floor ${floorNumber}`;
};

const formatColumnLabel = (columnNumber) => {
  return `Column-${columnNumber}`;
};

const AddTaskDialog = ({
  defaultOrg, defaultProject, assigneesByCreater, showAddTaskDialog,
  setShowAddTaskDialog, targetId, onTaskAdd, onTaskUpdate, onTaskDelete,
  profileId, library, editBlocks, editUnits, project, organization,
  parentTaskId, taskToUpdate,
}) => {
  const classes = useStyles();

  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);


  const [taskName, setTaskName] = useState("");
  const [comment, setComment] = useState("");
  const [selectedProgressHistory, setSelectedProgressHistory] = useState();
  const [filesArr, setFilesArr] = useState([]);
  const [fileIds, setFileIds] = useState([]);

  const [selectedDocs, setSelectedDocs] = useState([]);
  const [showDocPicker, setShowDocPicker] = useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [labels, setLabels] = useState([]);
  const [structures, setStructures] = useState([]);

  const [sops, setSOPs] = useState([]);
  const [selectedSOPs, setSelectedSOPs] = useState([]);
  const [checklists, setChecklists] = useState([]);

  const [checkpointIds, setCheckpointIds] = useState([]);
  const [checkpointDict, setCheckpointDict] = useState({});
  const [openLabelsDrawer, setOpenLabelsDrawer] = useState(false);
  const [assigned, setAssigned] = useState([]);
  const [initialProfileRole, setInitialProfileRole] = useState({});
  const [loadingPrimary, setLoadingPrimary] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [removeUnitIds, setRemoveUnitIds] = useState([]);
  const [showProgressHistoryDialog, setShowProgressHistoryDialog] = useState(false);
  const [selectedProgressHistoryFiles, setSelectedProgressHistoryFiles] = useState([]);
  const [selectUnits, setSelectUnits] = useState([]);
  const [category, setCategory] = useState(null);
  const [createCategoryDrawer, setCreateCategoryDrawer] = useState(false);
  const [showBlock, setShowBlock] = useState(true);
  const [removeFile, setRemoveFile] = useState({
    taskId: taskToUpdate?._id,
    removeFilesIds: [],
  });
  const [selectedChecklists, setSelectedChecklists] = useState([]);
  const [newChecklistTitle, setNewChecklistTitle] = useState("");
  const [newCheckpointDescription, setNewCheckpointDescription] = useState("");
  const [observationDialogOpen, setObservationDialogOpen] = useState(false);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null);
  const [schedulingInfo, setSchedulingInfo] = useState({
    predecessors: [],
    duration: 1,
    durationUnit: 'days',
    startDate: null,
    endDate: null,
    plannedStart: null,
    plannedFinish: null,
    earliestStartDate: null,
    earliestFinishDate: null,
    latestStartDate: null,
    latestFinishDate: null,
    slack: 0,
    isCritical: false
  });
  const [progress, setProgress] = useState(0);
  const [mspId, setMspId] = useState("");
  const [isValidatingMspId, setIsValidatingMspId] = useState(false);
  const [mspIdError, setMspIdError] = useState("");
  const [activeImageUploader, setActiveImageUploader] = useState(null);
  const [isDisabledOrg, setIsDisabledOrg] = useState(!selectedOrganization);
  const [isDisabledProject, setIsDisabledProject] = useState(!selectedProject);
  const [selectedBlocks, setSelectedBlocks] = useState([])
  const [selectedUnits, setSelectedUnits] = useState([])
  const [floors, setFloors] = useState([]);
  const [columns, setColumns] = useState([]);
  const [status, setStatus] = useState("")

  useEffect(() => {
    setSelectedBlocks(taskToUpdate?.projectBlocks || [])
    setSelectedUnits(taskToUpdate?.units || [])
  }, [taskToUpdate?.projectBlocks, taskToUpdate?.units])

  useEffect(() => {
    let locInitialProfileRole = {};
    if (organization?.profile) {
      locInitialProfileRole[organization?.profile] = "Owner";
    }
    if (user?.profile) {
      locInitialProfileRole[user?.profile] = "Owner";
    }
    if (project?.profile) {
      locInitialProfileRole[project?.profile] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [organization?.profile, user?.profile, project?.profile]);

  useEffect(() => {
    if (getLibraryId(library) && showAddTaskDialog) {
      fetchSOPs();
      fetchChecklists();
    }
  }, [library?._id, showAddTaskDialog]);

  useEffect(() => {
    if (taskToUpdate?._id) {
      setFloors(taskToUpdate?.floors || []);
      setColumns(taskToUpdate?.columns || []);
    }
  }, [taskToUpdate]);

  // Add this function to handle document selection
  const handleDocSelection = (docs) => {
    setSelectedDocs(docs);
    // You might want to update filesArr and fileIds here as well
    const newFileIds = docs.flatMap((doc) => doc.files.map((file) => file._id));
    const newFiles = docs.flatMap((doc) => doc.files);
    setFileIds((prevIds) => [...new Set([...prevIds, ...newFileIds])]);
    setFilesArr((prevFiles) => {
      const newFilesSet = new Set([...prevFiles, ...newFiles]);
      return Array.from(newFilesSet);
    });
  };

  const fetchSOPs = async () => {
    try {
      const res = await getSOPs({
        libraryId: getLibraryId(library),
      });
      let data = res?.data;

      if (Array.isArray(data)) {
        console.log("settingdata");
        setSOPs(data);
      }
    } catch (error) {
      console.error("Error fetching SOPs:", error);
    }
  };

  const fetchChecklists = async () => {
    try {
      const res = await getAllChecklists({
        libraryId: getLibraryId(library),
      });
      if (Array.isArray(res?.data)) {
        console.log(checklists, "are the checklists");
        setChecklists(res.data);
      }
    } catch (error) {
      console.error("Error fetching checklists:", error);
    }
  };

  const {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole,
    initAssigned: null,
    resourceId: taskToUpdate ? taskToUpdate._id : null,
    resourceName: taskToUpdate ? "WBS" : null,
    customShareBtn: null,
    customAssignBtn: assignBtn,
  });

  const handleSchedulingChange = (newInfo) => {
    // Prevent unnecessary updates by checking if values have actually changed
    const hasChanges = Object.keys(newInfo).some(key =>
      !_.isEqual(schedulingInfo[key], newInfo[key])
    );

    if (!hasChanges) return;

    setSchedulingInfo(prevInfo => {
      // Create new object with properly converted dates
      const updatedInfo = {
        ...prevInfo,
        ...newInfo,
        // Only convert dates if they exist and are different
        startDate: newInfo.startDate ? newInfo.startDate : prevInfo.startDate,
        endDate: newInfo.endDate ? newInfo.endDate : prevInfo.endDate,
        plannedStart: newInfo.plannedStart ? newInfo.plannedStart : prevInfo.plannedStart,
        plannedFinish: newInfo.plannedFinish ? newInfo.plannedFinish : prevInfo.plannedFinish,
        // Ensure numeric values are properly parsed
        duration: newInfo.duration !== undefined ? parseFloat(newInfo.duration) : prevInfo.duration,
        // Maintain other fields
        durationUnit: newInfo.durationUnit || prevInfo.durationUnit,
        predecessors: newInfo.predecessors || prevInfo.predecessors,
      };

      // Only return new object if it's different from previous
      return _.isEqual(prevInfo, updatedInfo) ? prevInfo : updatedInfo;
    });
  };

  const createTask = async () => {
    if (mspIdError) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please fix the Task ID error before creating the task",
        },
      });
      return;
    }
    try {
      setLoadingPrimary(true);

      const { data } = await Api.post("/wbs/create-custom", {
        targetId: targetId,
        taskName: taskName,
        library: getLibraryId(library),
        startDate: schedulingInfo.startDate?.toDate(),
        endDate: schedulingInfo.endDate?.toDate(),
        comment: comment,
        docs: selectedDocs.map((doc) => doc._id),
        files: filesArr,
        labels: labels?.map((l) => l?._id) || [],
        structures: structures?.map((l) => l?._id) || [],
        creatorProfileId: user?.profile,
        creatorId: user?._id,
        parentTaskId: parentTaskId,
        blockIds: selectedBlocks.map((s) => s?._id) || [],
        unitIds: selectedUnits.map((s) => s?._id) || [],
        organization: selectedOrganization?._id,
        project: selectedProject?._id || defaultProject?._id,
        sops: selectedSOPs.map((s) => s?._id),
        checkpoints: checkpointIds.map((checkpointId) => checkpointDict[checkpointId]),
        shared: sharedProfilesRole && sharedProfilesRole.length > 0 ? sharedProfilesRole.map((s) => s?._id) : [],
        assigned: assignedProfiles && assignedProfiles?.length > 0 ? assignedProfiles.map((s) => s?._id) : [],
        accessRoles: sharedProfilesRole,
        predecessors: schedulingInfo.predecessors || [],
        duration: schedulingInfo.duration || 0,
        durationUnit: schedulingInfo.durationUnit || 'days',
        plannedStart: schedulingInfo.plannedStart?.toDate(),
        plannedFinish: schedulingInfo.plannedFinish?.toDate(),
        mspId: mspId,
        floors: floors,
        columns: columns,
        status: status,
        profileId: profileId
      });
      console.log(data)
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task created successfully",
          },
        });
        setShowAddTaskDialog(false);
        setFileIds([]);
        setFilesArr([]);
        onTaskAdd(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while creating task",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingPrimary(false);
    }
  };

  const updateTask = async () => {
    try {
      setLoadingPrimary(true);
      const updateData = {
        _id: taskToUpdate?._id,
        updateBody: {
          taskName: taskName,
          removeFile: removeFile,
          startDate: schedulingInfo.startDate ? moment(schedulingInfo.startDate).toDate() : null,
          endDate: schedulingInfo.endDate ? moment(schedulingInfo.endDate).toDate() : null,
          comment: comment,
          files: filesArr,
          docs: selectedDocs.map((doc) => doc._id),
          labels: labels?.map((l) => l?._id) || [],
          structures: structures?.map((l) => l?._id),
          // category: category?._id,
          creator: user?.profile,
          projectBlocks: selectedBlocks.map((s) => s?._id) || [],
          units: selectedUnits.map((s) => s?._id) || [],
          sops: selectedSOPs.map((s) => s?._id),
          library: getLibraryId(library),
          checkpoints: checkpointIds,
          shared:
            sharedProfilesRole && sharedProfilesRole.length > 0
              ? sharedProfilesRole.map((s) => s?._id)
              : [],
          assigned:
            assignedProfiles && assignedProfiles?.length > 0
              ? assignedProfiles.map((s) => s?._id)
              : [],
          organization: selectedOrganization?._id,
          project: selectedProject?._id,
          accessRoles: sharedProfilesRole,
          predecessors: schedulingInfo.predecessors || [],
          duration: schedulingInfo.duration || 0,
          durationUnit: schedulingInfo.durationUnit || 'days',
          plannedStart: schedulingInfo.plannedStart ? moment(schedulingInfo.plannedStart).toDate() : null,
          plannedFinish: schedulingInfo.plannedFinish ? moment(schedulingInfo.plannedFinish).toDate() : null,
          floors: floors,
          columns: columns,
          approvalRequest: taskToUpdate?.approvalRequest,
          status: taskToUpdate?.status,
        },
      };

      console.log(updateData, ' istheupdateData')

      const { data } = await Api.post("/wbs/update-custom", updateData);
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task updated successfully",
          },
        });
        console.log(data,' task after update')
        onTaskUpdate(data);
        setShowAddTaskDialog(false);
        setFileIds([]);
        setFilesArr([]);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while updating task",
          },
        });
      }

    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingPrimary(false);
    }
  };

  const deleteTask = async () => {
    try {
      setLoadingSecondary(true);
      const { data } = await Api.post("/wbs/delete", {
        taskIds: [taskToUpdate?._id],
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Task deleted successfully",
        },
      });

      setShowAddTaskDialog(false);
      onTaskDelete(taskToUpdate);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingSecondary(false);
    }
  };

  useEffect(() => {
    //console.log("taskToUpdate: ", taskToUpdate?.units);
    if (taskToUpdate?._id) {
      console.log("taskToUpdate?.labels-", taskToUpdate);

      let groupByBlocks = _.groupBy(taskToUpdate?.units, "projectBlock");
      // setBlocks(taskToUpdate?.projectBlocks);
      setSelectUnits(groupByBlocks);
      setSelectedDocs(taskToUpdate?.docs || []);
      setTaskName(taskToUpdate?.taskName);
      setComment(taskToUpdate?.comment);
      setLabels(taskToUpdate?.labels?.filter(label => label != null) || []);
      if (taskToUpdate?.structures?.length > 0) {
        setStructures(taskToUpdate.structures.filter(structure => structure != null));
      }
      setCategory(taskToUpdate?.category);
      setAssigned(taskToUpdate?.assigned || []);
      setSelectedSOPs(taskToUpdate?.sops || []);
      setMspId(taskToUpdate?.mspId || "");

      if (taskToUpdate.checkpoints) {
        const { idArr, newDict } = arrayToReducer(taskToUpdate.checkpoints);
        setCheckpointIds(idArr);
        setCheckpointDict(newDict);
      }
      let _docs = taskToUpdate?.docs || [];
      let _files = [];
      for (let i = 0; i < _docs?.length; i++) {
        let _doc = _docs[i];
        let _docFiles = _doc?.files || [];
        for (let j = 0; j < _docFiles?.length; j++) {
          let _docFile = _docFiles[j];
          _files.push(_docFile);
        }
      }

      setFilesArr(_files);
      setFileIds(_files.map((f) => f?._id) || []);

      if (taskToUpdate?.projectBlocks?.length && taskToUpdate?.units?.length) {
        let groupByBlocks = _.groupBy(taskToUpdate?.units, "projectBlock");

        setSelectUnits(groupByBlocks);
      }
      // Update scheduling info with proper moment/dayjs conversion
      setSchedulingInfo({
        predecessors: taskToUpdate?.predecessors || [],
        duration: taskToUpdate?.duration || 1,
        durationUnit: taskToUpdate?.durationUnit || 'days',
        startDate: taskToUpdate?.startDate ? moment(taskToUpdate.startDate) : null,
        endDate: taskToUpdate?.endDate ? moment(taskToUpdate.endDate) : null,
        plannedStart: taskToUpdate?.plannedStart ? moment(taskToUpdate.plannedStart) : null,
        plannedFinish: taskToUpdate?.plannedFinish ? moment(taskToUpdate.plannedFinish) : null,
        earliestStartDate: taskToUpdate?.earliestStartDate ? moment(taskToUpdate.earliestStartDate) : null,
        earliestFinishDate: taskToUpdate?.earliestFinishDate ? moment(taskToUpdate.earliestFinishDate) : null,
        latestStartDate: taskToUpdate?.latestStartDate ? moment(taskToUpdate.latestStartDate) : null,
        latestFinishDate: taskToUpdate?.latestFinishDate ? moment(taskToUpdate.latestFinishDate) : null,
        slack: taskToUpdate?.slack || 0,
        isCritical: taskToUpdate?.isCritical || false
      });

      // Add this line to initialize progress
      setProgress(taskToUpdate?.progress || 0);
    }
  }, [taskToUpdate]); // Add _id to dependency array to ensure it runs when task changes

  // Add this function to check if predecessors are selected
  const hasPredecessors = useMemo(() => {
    return schedulingInfo.predecessors && schedulingInfo.predecessors.length > 0;
  }, [schedulingInfo.predecessors]);

  // Add this effect to get the next available mspId when creating a new task
  useEffect(() => {
    if (!taskToUpdate && showAddTaskDialog) {
      getNextAvailableMspId();
    }
  }, [taskToUpdate, showAddTaskDialog]);

  // Add this effect to validate mspId uniqueness
  useEffect(() => {
    const validateMspId = _.debounce(async () => {
      if (!mspId) return;

      setIsValidatingMspId(true);
      try {
        const response = await Api.post("wbs/validate-msp-id", {
          targetId: targetId,
          mspId: mspId,
          excludeTaskId: taskToUpdate?._id
        });

        if (!response.isValid) {
          setMspIdError("This Task ID is already in use");
        } else {
          setMspIdError("");
        }
      } catch (error) {
        console.error("Error validating Task ID:", error);
        setMspIdError("Error validating Task ID");
      } finally {
        setIsValidatingMspId(false);
      }
    }, 2000);

    validateMspId();
    return () => validateMspId.cancel();
  }, [mspId, targetId, taskToUpdate?._id]);

  const getNextAvailableMspId = async () => {
    try {
      const response = await Api.post("wbs/get-next-msp-id", {
        targetId: targetId
      });
      setMspId(response.nextMspId.toString());
    } catch (error) {
      console.error("Error getting next Task ID:", error);
    }
  };

  return (
    <>
      <NormalDrawer
        openDrawer={showAddTaskDialog}
        setOpenDrawer={setShowAddTaskDialog}
        anchor={"right"}
        width={"60vw"}
        title={taskToUpdate ? "Update Task" : "Create Task"}
        content={
          <>
            <div className={classes.drawerBody}>
              <Grid container spacing={2} >
                <Grid item xs={taskToUpdate?._id ? 12 : 6} className={classes.filterInputCont} >
                  <FormBox label="Task ID">
                    <TextField
                      placeholder="Enter Task ID"
                      value={mspId}
                      onChange={(e) => setMspId(e.target.value)}
                      disabled={!!taskToUpdate} // Disable for existing tasks
                      error={!!mspIdError}
                      helperText={mspIdError}
                      fullWidth={true}
                      InputProps={{
                        endAdornment: isValidatingMspId && (
                          <CircularProgress size={20} />
                        ),
                      }}
                    />
                  </FormBox>
                </Grid>
                {!taskToUpdate?._id && (
                  <Grid item xs={6} className={classes.filterInputCont} >
                    <FormBox label="Status">
                      <Autocomplete
                        options={getLinerStatuses("WBS")}
                        value={status}
                        onChange={(event, value) => {
                          setStatus(value)
                        }}
                        fullWidth
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            {...params}
                            placeholder={"Select status"}
                          />
                        )}
                      />
                    </FormBox>
                  </Grid>
                )}
              </Grid>
              <FormBox label="Task Name">
                <TextField
                  placeholder="Enter"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  fullWidth={true}
                />
              </FormBox>

              {/* Remove Progress FormBox and replace with SchedulingInfo */}
              <SchedulingInfo
                taskId={taskToUpdate?._id}
                projectId={selectedProject?._id || project?._id}
                schedulingInfo={schedulingInfo}
                onSchedulingChange={handleSchedulingChange}
                progress={progress}
                onProgressChange={setProgress}
              />

              <SpaceBetween
                left={
                  <FormBox label="Organization">
                    <div className={classes.organizationCover}>
                      <OrgPicker
                        disabled={isDisabledOrg}
                        selectedOrg={selectedOrganization}
                        setSelectedOrg={setSelectedOrganization}
                        defaultOrganizationId={organization?._id}
                        hideLabel={true}
                        fullWidth={true}
                      />
                      {isDisabledOrg ? (
                        <EditIcon
                          sx={{ fontSize: "1rem" }}
                          className={classes.editOrg}
                          onClick={() => setIsDisabledOrg(!isDisabledOrg)}
                        />
                      ) : (
                        <EditOff
                          sx={{ fontSize: "1rem" }}
                          className={classes.editOrg}
                          onClick={() => setIsDisabledOrg(!isDisabledOrg)}
                        />
                      )}
                    </div>
                  </FormBox>
                }
                right={
                  <FormBox label="Project">
                    <div className={classes.organizationCover}>
                      <ProjectPicker
                        disabled={isDisabledProject}
                        defaultProjectId={project?._id}
                        selectedProject={selectedProject}
                        setSelectedProject={setSelectedProject}
                        fullWidth={true}
                      />
                      {isDisabledProject ? (
                        <EditIcon
                          sx={{ fontSize: "1rem" }}
                          className={classes.editOrg}
                          onClick={() =>
                            setIsDisabledProject(!isDisabledProject)
                          }
                        />
                      ) : (
                        <EditOff
                          sx={{ fontSize: "1rem" }}
                          className={classes.editOrg}
                          onClick={() =>
                            setIsDisabledProject(!isDisabledProject)
                          }
                        />
                      )}
                    </div>
                  </FormBox>
                }
              />

              <BlockUnitViewer
                projectId={selectedProject?._id || taskToUpdate?.project}
                selectedBlocks={selectedBlocks}
                setSelectedBlocks={setSelectedBlocks}
                setSelectedUnits={setSelectedUnits}
                selectedUnits={selectedUnits}
              />


<SpaceBetween
                left={
                  <FormBox label="Floors">
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      value={floors}
                      onChange={(event, newValue) => {
                        // Convert string inputs to numbers and validate
                        const numericValues = newValue.map(val => {
                          const num = parseInt(val);
                          return isNaN(num) ? null : num;
                        }).filter(val => val !== null);
                        setFloors(numericValues);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter floor numbers (negative for basement)"
                          helperText="Enter floor numbers and press Enter"
                          size="small"
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            label={formatFloorLabel(option)}
                            {...getTagProps({ index })}
                            size="small"
                          />
                        ))
                      }
                    />
                  </FormBox>
                }
                right={
                  <FormBox label="Columns">
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      value={columns}
                      onChange={(event, newValue) => {
                        // Convert string inputs to numbers and validate
                        const numericValues = newValue.map(val => {
                          const num = parseInt(val);
                          return isNaN(num) ? null : num;
                        }).filter(val => val !== null);
                        setColumns(numericValues);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter column numbers"
                          helperText="Enter column numbers and press Enter"
                          size="small"
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            label={formatColumnLabel(option)}
                            {...getTagProps({ index })}
                            size="small"
                          />
                        ))
                      }
                    />
                  </FormBox>
                }
              />

              {taskToUpdate?._id && (<>
                <FormBox label="Documents">
                  <Button
                    variant="outlined"
                    onClick={(docs) => {
                      setShowDocPicker(true)

                    }}
                    startIcon={<Add />}
                  >
                    Select Documents
                  </Button>
                  {showDocPicker && (
                    <DocPickerDrawer
                      open={showDocPicker}
                      setOpen={setShowDocPicker}
                      onSelectionChange={handleDocSelection}
                      parentId={taskToUpdate?._id}
                      parentModelName={'WBS'}
                      onClose={(docs) => {
                        setShowDocPicker(false)
                        if (docs && docs.length > 0) {
                          setSelectedDocs(docs)
                        }
                      }}
                      initialProfileId={taskToUpdate?.project?.profile || taskToUpdate?.organization?.profile || user?.profile}
                      initialModelName={taskToUpdate?.project?._id ? "Project" : (taskToUpdate?.organization?._id ? "Organization" : 'User')}
                    />
                  )}
                  {selectedDocs.length > 0 && (
                    <Box mt={2}>
                      <Typography variant="subtitle1">
                        Selected Documents:
                      </Typography>
                      {selectedDocs.map((doc) => (
                        <Chip
                          key={doc._id}
                          label={doc.title}
                          onDelete={() =>
                            setSelectedDocs((prev) =>
                              prev.filter((d) => d._id !== doc._id)
                            )
                          }
                          style={{ margin: "4px" }}
                        />
                      ))}
                    </Box>
                  )}
                </FormBox>
              </>)}

              {/* <FormBox label="Comment">
              <TextField
                placeholder="Enter"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                fullWidth={true}
                rows={4}
                multiline={true}
              />
            </FormBox> */}
              <FormBox label="Structure">
                <CategoryAutocomplete
                  libraryId={getLibraryId(library)}
                  selectedCategories={structures}
                  setSelectedCategories={setStructures}
                  isMultiple={true}
                  label={"Structure"}
                  profileId={profileId}
                  type={"Structure"}
                  isCreateNew={true}
                  placeholder="Select Structure"
                />
              </FormBox>

              <FormBox label="Activities">
                <CategoryAutocomplete
                  libraryId={getLibraryId(library)}
                  selectedCategories={labels}
                  setSelectedCategories={setLabels}
                  isMultiple={true}
                  label={"Activity Type"}
                  profileId={profileId}
                  type={"Task"}
                  isCreateNew={true}
                  placeholder="Select/Add task activity category"
                />
              </FormBox>


              <FormBox label="Assignees">
                <Box sx={{ display: "flex", justifyContent: "start", my: 2 }}>
                  <AvatarGroup max={10}>
                    {assigneesByCreater?.map(({ parent }) => (
                      <BootstrapTooltip
                        placement="top"
                        title={parent?.displayName}
                      >
                        <Avatar
                          alt={parent?.displayName}
                          src={
                            parent?.displayPicture?.thumbUrl ||
                            parent?.displayPicture?.url
                          }
                        />
                      </BootstrapTooltip>
                    ))}
                  </AvatarGroup>
                </Box>
                {AssignButton} {AssigneDrawer}
                <div>{AssignView}</div>
              </FormBox>
              <FormBox label="Attachments">
                <DocumentUploader
                  documents={filesArr}
                  onUpload={() => {
                    setFileIds(createdFileIds);

                    let data = [];
                    for (let i = 0; i < createdFileIds?.length; i++) {
                      data.push(fileDictionary[createdFileIds[i]]);
                    }
                    let setValues = new Set([...filesArr, ...data]);
                    setFilesArr(Array.from(setValues));
                  }}
                  onDelete={(dataId) => {
                    setRemoveFile({
                      taskId: taskToUpdate?._id,
                      removeFilesIds: [...removeFile.removeFilesIds, dataId],
                    });
                    setFileIds(fileIds.filter((m) => m !== dataId));
                    setFilesArr(filesArr.filter((m) => m._id !== dataId));
                  }}
                  isImageUploaderActive={activeImageUploader != "checkpoint"}
                  onImageUploaderActivate={() =>
                    setActiveImageUploader("document")
                  }
                  onImageUploaderDeactivate={() => setActiveImageUploader(null)}
                />
                <Box>
                  {filesArr?.length > 0 ? (
                    <Box>
                      <DocViewer
                        data={filesArr}
                        isDeletable={true}
                        disableEditMetaData={true}
                        disableShowMetaData={true}
                        onMetadataChange={() => { }}
                        deleteFunction={(delId) => {
                          setRemoveFile({
                            taskId: taskToUpdate?._id,
                            removeFilesIds: [
                              ...removeFile.removeFilesIds,
                              delId,
                            ],
                          });
                          setFileIds(fileIds.filter((m) => m !== delId));
                          setFilesArr(filesArr.filter((m) => m._id !== delId));
                        }}
                      />
                    </Box>
                  ) : (
                    <Box>No documents found</Box>
                  )}
                </Box>
              </FormBox>


            </div>
            <div className={classes.drawerBottomBtn}>
              <DuoButtonGroup
                primaryButtonText={
                  taskToUpdate?._id ? "Update Task" : "Create Task"
                }
                primaryButtonListener={() => {
                  taskToUpdate?._id ? updateTask() : createTask();
                }}
                secondaryButtonText="Delete"
                secondaryButtonListener={() => deleteTask()}
                secondaryButtonColor="error"
                hideSecondary={!taskToUpdate?._id}
                loadingPrimary={loadingPrimary}
                loadingSecondary={loadingSecondary}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default AddTaskDialog;
