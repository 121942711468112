import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import SearchField from "../../styled/generic/SearchField";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  PaginationItem,
  IconButton,
  Pagination,
  Typography,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
  Check as CheckIcon,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import NumberFormat from "react-number-format";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, List, ListItem, ListItemText, Checkbox } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BookingCard from "./BookingCard";

export const calculateBookingFinancials = (booking) => {
  const agreementValue = booking?.paymentDetails?.reduce((total, component) => {
    if (component?.componentRef?.aggrementBased) {
      return total + component?.amountReceived
        ? parseInt(component?.amountReceived)
        : 0;
    }
    return total;
  }, 0);

  const totalReceivable = booking?.paymentDetails?.reduce((acc, detail) => {
    return acc + (detail.amountReceivable || 0);
  }, 0);
  const totalPaid = booking?.paymentDetails?.reduce((acc, detail) => {
    return acc + (detail?.amountReceived || 0);
  }, 0);
  const totalDue = totalReceivable - totalPaid;
  const paymentProgress = booking?.aggrement?.paymentProgress || 0;

  return {
    agreementValue,
    totalReceivable,
    totalPaid,
    totalDue,
    paymentProgress,
  };
};

const Bookings = ({ libraryId, projectIds }) => {
  const history = useHistory();
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterScreen, setFilterScreen] = useState("Projects");
  const [projects, setProjects] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [search, setSearch] = useState("");
  const [profile, setProfile] = useState(null);
  const [cancelledBookings, setCancelledBookings] = useState("all");

  const setUpProjects = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Project") {
        if (projects.includes(team?.parent)) {
          continue;
        } else {
          setProjects((prev) => [...prev, team?.parent]);
        }
      }
    }
  };

  const getBlocks = async () => {
    const data = await Api.post(
      "public/project/block/get/against/multiple-projects",
      { projectIds: projects.map((project) => project?._id) }
    );

    if (data && data?.data) {
      let restructuredBlocks = data?.data.map((block) => ({
        name: block.name,
        _id: block?._id,
        isRental: block.isRental,
        totalFloors: block.totalFloors,
        //  rentalUnits: item.rentalUnits || [],
      }));
      setBlocks(restructuredBlocks);
    }
  };

  useEffect(() => {
    if (projects.length > 0) {
      getBlocks();
    }
  }, [projects]);

  useEffect(() => {
    if (!projects.length > 0) {
      setUpProjects();
    }
  }, []);

  const BOOKING_STATUS = ["DRAFT", "SENT", "CHECKING", "PAID"];
  const FILTER_SCREENS = [
    { label: "Projects", size: projects.length },
    { label: "Status", size: BOOKING_STATUS.length },
  ];

  const handleClearAll = () => {
    setSelectedBlocks([]);
    setSelectedProjects([]);
    setSelectedStatus([]);
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setCurrentPage(newPage);
  };

  const getProfile = async () => {
    const data = await Api.post(`/profile/get/`, {
      profileIds: [profileId],
    });
    if (data) {
      setProfile(data[0]);
      console.log("profile", data);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to fetch profile",
        },
      });
    }
  };

  useEffect(() => {
    if (profileId) {
      getProfile();
    }
  }, [profileId]);

  const payload = {
    libraryId: libraryId,
    projectIds: [...selectedProjects, profile?.parent?._id, ...projectIds],
    blockIds: selectedBlocks,
    customerName: search,
    statuses: selectedStatus,
  };

  if (cancelledBookings !== "all") {
    payload.cancelled = cancelledBookings;
  }

  const getBookings = async (page = 1, limit = 12) => {
    const data = await Api.post(
      `/listing/booking/get?page=${page}&limit=${limit}`,
      payload
    );

    if (data) {
      setTotalPages(data.totalPages);
      setBookings(data?.bookings);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    if (libraryId && profile && projectIds) {
      getBookings().finally(() => {
        setPageLoading(false);
      });
    }
  }, [libraryId, profile, projectIds]);

  useEffect(() => {
    setGridLoading(true);
    getBookings(currentPage).finally(() => {
      setGridLoading(false);
    });
  }, [currentPage, search, cancelledBookings]);

  if (pageLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="85vh"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <StandardAppContainerRounded>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <SearchField
            fullWidth={true}
            placeholder="Search customer name"
            size="small"
            width="50%"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <FormControl
            sx={{
              width: "200px",
            }}
          >
            <InputLabel id="select-label">Status</InputLabel>
            <Select
              onChange={(e) => setCancelledBookings(e.target.value)}
              value={cancelledBookings}
              defaultValue={"all"}
              labelId="select-label"
              label="Age"
              variant="outlined"
              size="small"
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={true}>Cancelled</MenuItem>
              <MenuItem value={false}>Non-Cancelled</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={() => setShowFilter(true)}
            startIcon={<FilterAltIcon />}
          >
            Filter
          </Button>
        </Box>
        {gridLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="70vh"
            width="100%"
            flexDirection={"column"}
            gap={"10px"}
          >
            <CircularProgress />
            <Typography color={"primary"}>Loading...</Typography>
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {bookings?.map((booking) => {
              return (
                <Grid item lg={3} md={6} xs={12}>
                  <BookingCard booking={booking} />
                </Grid>
              );
            })}
          </Grid>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 2,
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => handlePageChange(page)}
            variant="outlined"
            shape="circular"
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
              />
            )}
          />
        </Box>

        {/* Filter drawer */}
        <FilterDrawer
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          gridLoading={gridLoading}
          setGridLoading={setGridLoading}
          filterScreen={filterScreen}
          setFilterScreen={setFilterScreen}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          projects={projects}
          BOOKING_STATUS={BOOKING_STATUS}
          getBookings={getBookings}
          handleClearAll={handleClearAll}
          FILTER_SCREENS={FILTER_SCREENS}
        />

        {/* <Drawer
          anchor="right"
          open={showFilter}
          onClose={() => {
            if (!gridLoading) {
              setShowFilter(false);
            }
          }}
          variant="temporary"
          sx={{
            width: 500,
            flexShrink: 0,
            "& .MuiDrawer-paper": { width: 500 },
          }}
        >
          <Box
            sx={{
              px: 1.5,
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                p: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" color={"primary"}>
                Filters
              </Typography>
              <Button
                disabled={gridLoading}
                variant="outlined"
                size="small"
                onClick={() => {
                  setGridLoading(true);
                  getBookings().finally(() => {
                    handleClearAll();
                    setShowFilter(false);
                    setGridLoading(false);
                  });
                }}
              >
                Reset all
              </Button>
            </Box>
            <Divider />
            <Box display={"flex"} flexGrow={1}>
              <List
                sx={{
                  flex: 1.5,
                }}
              >
                {FILTER_SCREENS.map((filter, index) => (
                  <>
                    <ListItem
                      key={index}
                      button
                      sx={{
                        display: "flex",
                        backgroundColor:
                          filterScreen === filter.label && "#e4e4e7",
                      }}
                      onClick={() => setFilterScreen(filter.label)}
                    >
                      <ListItemText primary={filter.label} />
                      <Typography>{filter.size}</Typography>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
              <Divider orientation="vertical" variant="middle" flexItem />
              {filterScreen === "Projects" && (
                <List
                  sx={{
                    flex: 2.5,
                  }}
                >
                  {projects.map((project, index) => (
                    <>
                      <ListItem
                        key={index}
                        button
                        onClick={() => {
                          if (selectedProjects.includes(project?._id)) {
                            setSelectedProjects((prev) =>
                              prev.filter((item) => item !== project?._id)
                            );
                          } else {
                            setSelectedProjects((prev) => [
                              ...prev,
                              project?._id,
                            ]);
                          }
                        }}
                      >
                        <Checkbox
                          checked={selectedProjects.includes(project?._id)}
                        />
                        <ListItemText primary={project.displayName} />
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              )}

              {filterScreen === "Status" && (
                <List
                  sx={{
                    flex: 2.5,
                  }}
                >
                  {BOOKING_STATUS.map((status) => (
                    <>
                      <ListItem
                        key={status}
                        button
                        onClick={() => {
                          if (selectedStatus.includes(status)) {
                            setSelectedStatus((prev) =>
                              prev.filter((item) => item !== status)
                            );
                          } else {
                            setSelectedStatus((prev) => [...prev, status]);
                          }
                        }}
                      >
                        <Checkbox checked={selectedStatus.includes(status)} />
                        <ListItemText primary={status} />
                      </ListItem>
                      <Divider />{" "}
                    </>
                  ))}
                </List>
              )}
            </Box>
            <Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "15px",
                  py: "20px",
                }}
              >
                <Button
                  disabled={gridLoading}
                  variant="outlined"
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  Close
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={gridLoading}
                  onClick={() => {
                    setGridLoading(true);
                    getBookings().finally(() => {
                      setGridLoading(false);
                      setShowFilter(false);
                    });
                  }}
                >
                  APPLY
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Drawer> */}
      </StandardAppContainerRounded>
    </>
  );
};

export default Bookings;

const FilterDrawer = ({
  showFilter,
  setShowFilter,
  gridLoading,
  setGridLoading,
  filterScreen,
  setFilterScreen,
  selectedProjects,
  setSelectedProjects,
  selectedStatus,
  setSelectedStatus,
  projects,
  BOOKING_STATUS,
  getBookings,
  handleClearAll,
  FILTER_SCREENS,
}) => {
  return (
    <Drawer
      anchor="right"
      open={showFilter}
      onClose={() => {
        if (!gridLoading) {
          setShowFilter(false);
        }
      }}
      variant="temporary"
      sx={{
        "& .MuiDrawer-paper": {
          width: 500,
          maxWidth: "100%",
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Typography
            variant="h6"
            color="primary"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <FilterListIcon /> Filters
          </Typography>
          <Box>
            <Button
              disabled={gridLoading}
              variant="outlined"
              size="small"
              startIcon={<RefreshIcon />}
              onClick={() => {
                setGridLoading(true);
                getBookings().finally(() => {
                  handleClearAll();
                  setShowFilter(false);
                  setGridLoading(false);
                });
              }}
              sx={{ mr: 1 }}
            >
              Reset all
            </Button>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setShowFilter(false)}
              disabled={gridLoading}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box display="flex" flexGrow={1} sx={{ overflow: "hidden" }}>
          <List
            sx={{
              width: 200,
              borderRight: 1,
              borderColor: "divider",
              overflowY: "auto",
            }}
          >
            {FILTER_SCREENS.map((filter, index) => (
              <ListItem
                key={index}
                button
                selected={filterScreen === filter.label}
                onClick={() => setFilterScreen(filter.label)}
              >
                <ListItemText primary={filter.label} />
                <Typography variant="caption" color="text.secondary">
                  {filter.size}
                </Typography>
              </ListItem>
            ))}
          </List>

          <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
            {filterScreen === "Projects" && (
              <List>
                {projects.map((project, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      setSelectedProjects((prev) =>
                        prev.includes(project?._id)
                          ? prev.filter((item) => item !== project?._id)
                          : [...prev, project?._id]
                      );
                    }}
                  >
                    <Checkbox
                      checked={selectedProjects.includes(project?._id)}
                      color="primary"
                    />
                    <ListItemText primary={project.displayName} />
                  </ListItem>
                ))}
              </List>
            )}

            {filterScreen === "Status" && (
              <List>
                {BOOKING_STATUS.map((status) => (
                  <ListItem
                    key={status}
                    button
                    onClick={() => {
                      setSelectedStatus((prev) =>
                        prev.includes(status)
                          ? prev.filter((item) => item !== status)
                          : [...prev, status]
                      );
                    }}
                  >
                    <Checkbox
                      checked={selectedStatus.includes(status)}
                      color="primary"
                    />
                    <ListItemText primary={status} />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button
            disabled={gridLoading}
            variant="outlined"
            onClick={() => setShowFilter(false)}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={gridLoading}
            onClick={() => {
              setGridLoading(true);
              getBookings().finally(() => {
                setGridLoading(false);
                setShowFilter(false);
              });
            }}
            startIcon={<CheckIcon />}
          >
            Apply
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};
