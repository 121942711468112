const { default: Api } = require("../../helpers/Api");

export const getOrgAnalytics = async ({ userId, orgId }) => {
  try {
    const res = await Api.post("organization/get/analytics", { orgId });
    const data = res?.data || {};
    return data?.analytics;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getProjectAnalytics = async ({ projectId, blockIds }) => {
  const res = await Api.post("projects/get/analytics", { projectId, blockIds });
  const data = res?.data || {};
  return data?.analytics;
};
