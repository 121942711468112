const allUnitStatuses = {
  // multiStatusAllowed:[ "Construction"],
  multiStatusAllowed: [],
  // Construction: [
  //   "Planning",
  //   "Permitting",
  //   "Site Preparation",
  //   "Foundation Work",
  //   "Framing",
  //   "Roofing",
  //   "Exterior Work",
  //   "Plumbing Rough-In",
  //   "Electrical Rough-In",
  //   "HVAC Installation",
  //   "Insulation",
  //   "Drywall Installation",
  //   "Interior Finishes",
  //   "Flooring Installation",
  //   "Cabinet and Countertop Installation",
  //   "Fixture Installation",
  //   "Painting",
  //   "Final Touches",
  //   "Inspection",
  //   "Certificate of Occupancy Obtained",
  //   "Construction Completed",
  // ],
  Listing: [
    "Listed on Real Estate Websites",
    "Open House Scheduled",
    "Private Showings Available",
    "Offer Received",
    "Under Contract",
    "Pending Sale",
    "Sale Cancelled",
    "Temporarily Off Market",
    "Price Reduced",
    "Back on Market",
    "Listing Expired",
    "Listing Withdrawn",
  ],
  Rent: [
    "Available for Rent",
    "Rent Price Set",
    "Listing Created",
    "Marketing Active",
    "Showings Scheduled",
    "Application Received",
    "Tenant Screening in Progress",
    "Lease Negotiation",
    "Lease Signed",
    "Security Deposit Received",
    "Move-in Inspection Scheduled",
    "Tenant Move-in",
    "Occupied",
    "Rent Overdue",
    "Maintenance Required",
    "Lease Renewal Offered",
    "Lease Renewed",
    "Notice to Vacate Received",
    "Pre-move-out Inspection Scheduled",
    "Tenant Move-out",
    "Move-out Inspection Completed",
    "Security Deposit Settlement",
    "Vacant - Ready for New Tenant",
    "Renovations/Repairs in Progress",
  ],
  Availability: ["Available", "Sold", "Rented"],
  Ownership: [
    "Investor",
    "Landowner",
    "Developer / Project owner",
    "Buyer",
    "Partners",
  ],
  Sale: [
    "Available for Sale",
    "Sale Price Set",
    "Listing Created",
    "Marketing Active",
    "Showings Scheduled",
    "Offer Received",
    "Offer Negotiation",
    "Contract Signed",
    "Sale Pending",
    "Inspection Completed",
    "Sale Finalized",
    "Ownership Transferred",
    "Sale Cancelled",
    "Back on Market",
  ],
};

export default allUnitStatuses;
