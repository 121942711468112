import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "../../../../styled/generic/Dialog";
import FormBox from "../../../../styled/generic/FormBox";
import TextField from "../../../../styled/generic/TextField";
import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";
import Api from "../../../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({

}));

const validationSchema = yup.object().shape({
    beneficiaryName: yup.string().required("Beneficiary Name is required"),
    bankName: yup.string().required("Bank Name is required"),
    accountNumber: yup.string().required("Account Number is required"),
    ifsc: yup.string().required("IFSC is required"),
    branchName: yup.string().required("Branch Name is required"),
});

export default function AddIndianBankAccountDialog({
    showBankDialog, setShowBankDialog, handledByWalletOwner = true,
    onAccountAdded, setIsAddingAccount, walletId, contactModelName,
    contactId
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [beneficiaryName, setBeneficiaryName] = useState("");
    const [bankName, setBankName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [branchName, setBranchName] = useState("");
    const [errors, setErrors] = useState({});

    const createBank = async () => {
        try {
            await validationSchema.validate(
                { beneficiaryName, bankName, accountNumber, ifsc, branchName },
                { abortEarly: false }
            );
            setIsAddingAccount(true);
            const { data } = await Api.post("/wallet/indian/bank/create", {
                beneficiaryName,
                bankName,
                accountNumber,
                ifsc,
                branchName,
                wallet: walletId,
                handledByWalletOwner,
                contactModelName,
                contactId
            });
            if (data) {
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: true,
                        message: "Bank added successfully",
                    },
                });
                setShowBankDialog(false);
                onAccountAdded(data);
            } else {
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: false,
                        message: "An unknown error occurred",
                    },
                });
            }
        } catch (error) {
            if (error.name === "ValidationError") {
                const validationErrors = {};
                error.inner.forEach((err) => {
                    validationErrors[err.path] = err.message;
                });
                setErrors(validationErrors);
            } else {
                console.log(error);
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: false,
                        message: "An unknown error occurred" + error,
                    },
                });
            }
        } finally {
            setIsAddingAccount(false);
        }
    };

    return (
        <Dialog
            open={showBankDialog}
            setOpen={setShowBankDialog}
            title="Indian Bank"
        >
            <FormBox label="Beneficiary Name">
                <TextField
                    value={beneficiaryName}
                    onChange={(e) => setBeneficiaryName(e.target.value)}
                    placeholder="Enter Beneficiary Name"
                    error={!!errors.beneficiaryName}
                    helperText={errors.beneficiaryName}
                    fullWidth={true}

                />
            </FormBox>
            <FormBox label="Bank Name">
                <TextField
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    placeholder="Enter Bank Name"
                    error={!!errors.bankName}
                    helperText={errors.bankName}
                    fullWidth={true}
                />
            </FormBox>
            <FormBox label="Account Number">
                <TextField
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    placeholder="Enter Account Number"
                    error={!!errors.accountNumber}
                    helperText={errors.accountNumber}
                    fullWidth={true}
                />
            </FormBox>
            <FormBox label="IFSC">
                <TextField
                    value={ifsc}
                    onChange={(e) => setIfsc(e.target.value)}
                    placeholder="Enter IFSC"
                    error={!!errors.ifsc}
                    helperText={errors.ifsc}
                    fullWidth={true}
                />
            </FormBox>
            <FormBox label="Branch Name">
                <TextField
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    placeholder="Enter Branch Name"
                    error={!!errors.branchName}
                    helperText={errors.branchName}
                    fullWidth={true}
                />
            </FormBox>
            <DuoButtonGroup
                primaryButtonText="Save"
                primaryButtonListener={() => createBank()}
                secondaryButtonText="Cancel"
                secondaryButtonListener={() => setShowBankDialog(false)}
            />
        </Dialog>
    );
}