import { Box, Typography, Grid } from "@material-ui/core";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Apartment as ApartmentIcon,
  CheckCircle as CheckCircleIcon,
  MonetizationOn as MonetizationOnIcon,
  Home as HomeIcon,
  Dashboard as DashboardIcon,
  AspectRatio as AspectRatioIcon,
} from "@material-ui/icons";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"];

export default function OrgAnalyticsCharts({
  data,
  selectedProjects,
  selectedBlocks,
}) {
  const allBlocks = data.flatMap((project) =>
    project.analytics.filter(
      (block) =>
        (!selectedBlocks?.length || selectedBlocks.includes(block._id)) &&
        (!selectedProjects?.length ||
          selectedProjects.includes(project.project._id))
    )
  );

  const ownershipData = Object.entries({
    developer: "Developer",
    investor: "Investor",
    landowner: "Landowner",
    buyer: "Buyer",
    partner: "Partner",
  })
    .map(([key, label]) => {
      const stats = allBlocks.reduce(
        (acc, block) => ({
          total: acc.total + (block.analytics[key]?.total || 0),
          available: acc.available + (block.analytics[key]?.available || 0),
          sold: acc.sold + (block.analytics[key]?.sold || 0),
          rented: acc.rented + (block.analytics[key]?.rented || 0),
        }),
        { total: 0, available: 0, sold: 0, rented: 0 }
      );

      return {
        name: label,
        total: stats.total,
        available: stats.available,
        sold: stats.sold,
        rented: stats.rented,
      };
    })
    .filter((item) => item.total > 0);

  const availabilityData = allBlocks.reduce((acc) => {
    const available = allBlocks.reduce(
      (sum, block) => sum + block.totalAvailable,
      0
    );
    const sold = allBlocks.reduce((sum, block) => sum + block.totalSold, 0);
    const rented = allBlocks.reduce((sum, block) => sum + block.totalRented, 0);

    return [
      { name: "Available", value: available },
      { name: "Sold", value: sold },
      { name: "Rented", value: rented },
    ].filter((item) => item.value > 0);
  }, []);

  const blockComparisonData = allBlocks.map((block) => ({
    name: block.blockName,
    units: block.totalUnits,
    available: block.totalAvailable,
    sold: block.totalSold,
    rented: block.totalRented,
  }));

  const ownershipByBlockData = allBlocks.map((block) => ({
    name: block.blockName,
    developerUnits: block.analytics.developer?.total || 0,
    investorUnits: block.analytics.investor?.total || 0,
    totalUnits: block.totalUnits,
  }));

  const chartConfig = {
    barSize: 80,
    margin: { top: 5, right: 5, left: 5, bottom: 5 },
  };

  const statistics = {
    totalUnits: allBlocks.reduce((sum, block) => sum + block.totalUnits, 0),
    totalAvailable: allBlocks.reduce(
      (sum, block) => sum + block.totalAvailable,
      0
    ),
    totalSold: allBlocks.reduce((sum, block) => sum + block.totalSold, 0),
    totalRented: allBlocks.reduce((sum, block) => sum + block.totalRented, 0),
    totalBlocks: allBlocks.length,
    totalArea: allBlocks.reduce(
      (sum, block) => sum + (block.totalArea || 0),
      0
    ),
    totalUnsoldArea: allBlocks.reduce(
      (sum, block) => sum + (block.totalUnsoldArea || 0),
      0
    ),
    avgBaseRate:
      data
        .filter(
          (project) =>
            !selectedProjects?.length ||
            selectedProjects.includes(project.project._id)
        )
        .reduce(
          (sum, project) => sum + (project.project?.avgBaseRate || 0),
          0
        ) /
      (data.filter(
        (project) =>
          !selectedProjects?.length ||
          selectedProjects.includes(project.project._id)
      ).length || 1),
    unsoldInventoryValue: data
      .filter(
        (project) =>
          !selectedProjects?.length ||
          selectedProjects.includes(project.project._id)
      )
      .reduce(
        (sum, project) => sum + (project.project?.unsoldInventoryValue || 0),
        0
      ),
  };

  return (
    <Box mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight="500"
            color="primary"
          >
            Summary Statistics
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Box p={2} bgcolor="#f5f5f5" borderRadius={2} boxShadow={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <ApartmentIcon color="primary" sx={{ fontSize: 24 }} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  ml={1}
                  fontWeight="500"
                >
                  Total Units
                </Typography>
              </Box>
              <Typography variant="h5" fontWeight="600">
                {statistics.totalUnits.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Box p={2} bgcolor="#f5f5f5" borderRadius={2} boxShadow={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <HomeIcon color="primary" sx={{ fontSize: 24 }} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  ml={1}
                  fontWeight="500"
                >
                  Available
                </Typography>
              </Box>
              <Typography variant="h5" fontWeight="600">
                {statistics.totalAvailable.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Box p={2} bgcolor="#f5f5f5" borderRadius={2} boxShadow={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <MonetizationOnIcon color="primary" sx={{ fontSize: 24 }} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  ml={1}
                  fontWeight="500"
                >
                  Sold
                </Typography>
              </Box>
              <Typography variant="h5" fontWeight="600">
                {statistics.totalSold.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Box p={2} bgcolor="#f5f5f5" borderRadius={2} boxShadow={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <CheckCircleIcon color="primary" sx={{ fontSize: 24 }} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  ml={1}
                  fontWeight="500"
                >
                  Rented
                </Typography>
              </Box>
              <Typography variant="h5" fontWeight="600">
                {statistics.totalRented.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Box p={2} bgcolor="#f5f5f5" borderRadius={2} boxShadow={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <DashboardIcon color="primary" sx={{ fontSize: 24 }} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  ml={1}
                  fontWeight="500"
                >
                  Total Blocks
                </Typography>
              </Box>
              <Typography variant="h5" fontWeight="600">
                {statistics.totalBlocks.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Box p={2} bgcolor="#f5f5f5" borderRadius={2} boxShadow={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <AspectRatioIcon color="primary" sx={{ fontSize: 24 }} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  ml={1}
                  fontWeight="500"
                >
                  Total Area
                </Typography>
              </Box>
              <Typography variant="h5" fontWeight="600">
                {statistics.totalArea.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Box p={2} bgcolor="#f5f5f5" borderRadius={2} boxShadow={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <AspectRatioIcon color="primary" sx={{ fontSize: 24 }} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  ml={1}
                  fontWeight="500"
                >
                  Unsold Area
                </Typography>
              </Box>
              <Typography variant="h5" fontWeight="600">
                {statistics.totalUnsoldArea.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Box p={2} bgcolor="#f5f5f5" borderRadius={2} boxShadow={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <MonetizationOnIcon color="primary" sx={{ fontSize: 24 }} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  ml={1}
                  fontWeight="500"
                >
                  Avg Base Rate
                </Typography>
              </Box>
              <Typography variant="h5" fontWeight="600">
                ₹{" "}
                {statistics.avgBaseRate.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box p={2} bgcolor="#f5f5f5" borderRadius={2} boxShadow={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <MonetizationOnIcon color="primary" sx={{ fontSize: 24 }} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  ml={1}
                  fontWeight="500"
                >
                  Unsold Inventory Value
                </Typography>
              </Box>
              <Typography variant="h5" fontWeight="600">
                ₹{" "}
                {statistics.unsoldInventoryValue?.toLocaleString(undefined, {
                  maximunFractionDigits: 0,
                })}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Units by Ownership Type
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={ownershipData}
              margin={chartConfig.margin}
              barSize={chartConfig.barSize}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="available"
                stackId="a"
                fill="#82ca9d"
                name="Available"
              />
              <Bar dataKey="sold" stackId="a" fill="#8884d8" name="Sold" />
              <Bar dataKey="rented" stackId="a" fill="#ffc658" name="Rented" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Overall Availability Status
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart margin={chartConfig.margin}>
              <Pie
                data={availabilityData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label={({ name, percent }) =>
                  `${name} ${(percent * 100).toFixed(0)}%`
                }
              >
                {availabilityData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Block-wise Unit Distribution
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={blockComparisonData}
              margin={chartConfig.margin}
              barSize={chartConfig.barSize}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="available"
                stackId="a"
                fill="#82ca9d"
                name="Available"
              />
              <Bar dataKey="sold" stackId="a" fill="#8884d8" name="Sold" />
              <Bar dataKey="rented" stackId="a" fill="#ffc658" name="Rented" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Developer vs Investor Units by Block
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={ownershipByBlockData}
              margin={chartConfig.margin}
              barSize={chartConfig.barSize}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="developerUnits"
                fill="#8884d8"
                name="Developer Units"
              />
              <Bar
                dataKey="investorUnits"
                fill="#82ca9d"
                name="Investor Units"
              />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
