// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Api from "../../helpers/Api";
// import FormBox from "../styled/generic/FormBox";
// import TextField from "../styled/generic/TextField";
// import { useDispatch, useSelector } from "react-redux";

// const useStyles = makeStyles({
//   container: {
//     width: "100%",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   header: {
//     width: "100%",
//     height: "4rem",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "end",
//     paddingRight: "1rem",
//     borderBottom: "1px solid #e4e7e7",
//   },
//   saveBtn: {
//     width: "6rem",
//     height: "2.5rem",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundColor: "#2d76e0",
//     color: "white",
//     borderRadius: "0.4rem",
//     fontSize: "1.1rem",
//   },
//   inputCont: {
//     paddingTop: "2rem",
//     width: "95%",
//     display: "grid",
//     gridTemplateColumns: "repeat(2,1fr)",
//     gap: "1rem",
//   },
// });

// const BankEdit = ({ organization }) => {
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.auth);
//   const [showBankDialog, setShowBankDialog] = useState(false);
//   const [beneficiaryName, setBeneficiaryName] = useState("");
//   const [bankName, setBankName] = useState("");
//   const [accountNumber, setAccountNumber] = useState("");
//   const [ifsc, setIfsc] = useState("");
//   const [branchName, setBranchName] = useState("");
//   const classes = useStyles();

//   const saveEdit = async () => {
//     try {
//       const { data } = await Api.post("/wallet/indian/bank/create", {
//         beneficiaryName,
//         bankName,
//         accountNumber,
//         ifsc,
//         branchName,
//         wallet: organization?.wallet,
//       });
//       if (data) {
//         dispatch({
//           type: "AddApiAlert",
//           payload: {
//             success: true,
//             message: "Bank added successfully",
//           },
//         });
//         setShowBankDialog(false);
//       } else {
//         dispatch({
//           type: "AddApiAlert",
//           payload: {
//             success: false,
//             message: "An unknown error occurred",
//           },
//         });
//       }
//     } catch (error) {
//       console.log(error);
//       dispatch({
//         type: "AddApiAlert",
//         payload: {
//           success: false,
//           message: "An unknown error occurred" + error,
//         },
//       });
//     }
//   };

//   return (
//     <div className={classes.container}>
//       <div className={classes.header}>
//         <span className={classes.saveBtn}>Save</span>
//       </div>
//       <div className={classes.inputCont}>
//         <FormBox label="Beneficiary Name">
//           <TextField
//             value={beneficiaryName}
//             onChange={(e) => setBeneficiaryName(e.target.value)}
//             placeholder="Enter Beneficiary Name"
//             fullWidth={true}
//           />
//         </FormBox>
//         <FormBox label="Bank Name">
//           <TextField
//             value={bankName}
//             onChange={(e) => setBankName(e.target.value)}
//             placeholder="Enter Bank Name"
//             style={{ width: "100%" }}
//           />
//         </FormBox>
//         <FormBox label="Account Number">
//           <TextField
//             value={accountNumber}
//             onChange={(e) => setAccountNumber(e.target.value)}
//             placeholder="Enter Account Number"
//             style={{ width: "100%" }}
//           />
//         </FormBox>
//         <FormBox label="IFSC">
//           <TextField
//             value={ifsc}
//             onChange={(e) => setIfsc(e.target.value)}
//             placeholder="Enter IFSC"
//             style={{ width: "100%" }}
//           />
//         </FormBox>
//         <FormBox label="Branch Name">
//           <TextField
//             value={branchName}
//             onChange={(e) => setBranchName(e.target.value)}
//             placeholder="Enter Branch Name"
//             style={{ width: "100%" }}
//           />
//         </FormBox>
//       </div>
//     </div>
//   );
// };

// export default BankEdit;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Api from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import TextField from "../styled/generic/TextField";
import FormBox from "../styled/generic/FormBox";
import { getBankAccounts } from "../finance2o/accounts/api.call";
import PlaidBankCard from "../finance2o/accounts/BankAccounts/plaid/plaid.bank.card";
import PlaidBankSkeleton from "../styled/skeletons/PlaidBankCardSkeleton";
import IndianBankCard from "../finance2o/accounts/BankAccounts/plaid/indian.bank.card";
import IndianLinkBtn from "../finance2o/accounts/BankAccounts/plaid/indian.bank.btn";
import { Grid} from "@mui/material";
import * as yup from "yup";

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    paddingBottom: "2rem",
  },
  header: {
    width: "100%",
    height: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingRight: "1rem",
    borderBottom: "1px solid #e4e7e7",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  inputCont: {
    paddingTop: "2rem",
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
  bankDetailsCardCont: {
    paddingTop: "2rem",
    width: "95%",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    flexDirection: "column", // Changed to column to stack the cards
  },


allCardCont: {
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
},
title: {
  fontSize: "25px",
  fontWeight: "600",
  color: "#00345d",
  margin: "50px 0px 15px",
},
emptyCont: {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "30px 10px",
  "& img": {
    width: "250px",
    height: "auto",
  },
  "& p": {
    fontSize: "15px",
    fontWeight: "400",
    marginTop: "10px",
  },
},
loadingIndicator: {
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
},
});

const validationSchema = yup.object().shape({
  beneficiaryName: yup.string().required("Beneficiary Name is required"),
  bankName: yup.string().required("Bank Name is required"),
  accountNumber: yup.string().required("Account Number is required"),
  ifsc: yup.string().required("IFSC is required"),
  branchName: yup.string().required("Branch Name is required"),
});

const BankEdit = ({ organization }) => {
const classes = useStyles();
const { user } = useSelector((state) => state.auth);
const [beneficiaryName, setBeneficiaryName] = useState("");
const [bankName, setBankName] = useState("");
const [accountNumber, setAccountNumber] = useState("");
const [ifsc, setIfsc] = useState("");
const [branchName, setBranchName] = useState("");
const [loading, setLoading] = useState(false);
const [bankDetails, setBankDetails] = useState([]);
const [addBankDetail, setAddBankDetail] = useState(false);
const [editBankDetail, setEditBankDetail] = useState({});
const [bankDetailEditId, setBankDetailEditId] = useState();
const [createBankDetailLoad, setCreateBankDetailLoad] = useState(false);
const [updateBankDetailLoad, setUpdateBankDetailLoad] = useState(false);
const [plaidBankAccounts, setPlaidBankAccounts] = useState([]);
const [isAddingAccount, setIsAddingAccount] = useState(false);
const [errors, setErrors] = useState({});
const dispatch = useDispatch();

const getBankDetails = async () => {
  try {
    const { data } = await Api.post("/wallet/indian/bank/get", {
      organization: organization?.wallet,
    });
    if (data) {
      setBankDetails(data);
    }
  } catch (err) {
    dispatch({
      type: "AddApiAlert",
      payload: {
        success: false,
        message: "Unable to fetch bank details",
      },
    });
  }
};

const getBankAccountsApiHit = async () => {
  setLoading(true);
  try {
    const data = await getBankAccounts({ walletId: organization?.wallet });
    setPlaidBankAccounts(data);
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  getBankDetails();
  getBankAccountsApiHit();
}, [organization]);

const handleSetEditBankDetail = (detail) => {
  setBeneficiaryName(detail?.beneficiaryName);
  setBankName(detail?.bankName);
  setAccountNumber(detail?.accountNumber);
  setIfsc(detail?.ifsc);
  setBranchName(detail?.branchName);
  setBankDetailEditId(detail?._id);
  setAddBankDetail(true);
};

const handleClearEdit = () => {
  setBeneficiaryName("");
  setBankName("");
  setAccountNumber("");
  setIfsc("");
  setBranchName("");
  setBankDetailEditId("");
  setAddBankDetail(false);
};

const createBankDetail = async () => {
  try {
    await validationSchema.validate(
      { beneficiaryName, bankName, accountNumber, ifsc, branchName },
      { abortEarly: false }
    );
    setCreateBankDetailLoad(true);
    const { data } = await Api.post("/wallet/indian/bank/create", {
      beneficiaryName,
      bankName,
      accountNumber,
      ifsc,
      branchName,
      wallet: organization?.wallet,
    });

    if (data?._id) {
      setBankDetails([...bankDetails, { ...data }]);
      handleClearEdit();
      refreshBankAccounts();
      
    }
  } catch (error) {
    if (error.name === "ValidationError") {
const validationErrors = {};
error.inner.forEach((err) => {
  validationErrors[err.path] = err.message;
});
setErrors(validationErrors);
} else {
console.log(error);
dispatch({
  type: "AddApiAlert",
  payload: {
    success: false,
    message: "An unknown error occurred" + error,
  },
});
}
  } finally {
    setCreateBankDetailLoad(false);
  }
};

const updateBankDetail = async () => {
  try {
    setUpdateBankDetailLoad(true);
    const { data } = await Api.post("/wallet/indian/bank/update", {
      _id: bankDetailEditId,
      updateBody: {
        beneficiaryName,
        bankName,
        accountNumber,
        ifsc,
        branchName,
        wallet: organization?.wallet,
      },
    });

    const index = bankDetails?.findIndex(({ _id }) => _id === data?._id);
    const newBankDetails = [...bankDetails];
    newBankDetails.splice(index, 1, { ...data });
    setBankDetails(newBankDetails);
    refreshBankAccounts();
    
    handleClearEdit();
  } catch (error) {
    console.error(error);
  } finally {
    setUpdateBankDetailLoad(false);
  }
};

const deleteBankDetail = async (id) => {
  try {
    const { data } = await Api.post("/wallet/indian/bank/delete", { _id: id });
    const index = bankDetails?.findIndex(({ _id }) => _id === data?._id);
    const newBankDetails = [...bankDetails];
    newBankDetails.splice(index, 1);
    setBankDetails(newBankDetails);
  } catch (error) {
    console.error(error);
  }
};

const refreshBankAccounts = () => {
  getBankAccountsApiHit();
};

return (
  <div className={classes.container}>
    <div className={classes.header}>
      <span style={{ fontSize: "1.4rem" }}></span>
      <div className={classes.headerRight}>
        <div
          onClick={() => setAddBankDetail(true)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
            padding: "0.5rem 1rem",
            borderRadius: "0.4rem",
            backgroundColor: "#2d76e0",
            fontSize: "1.1rem",
            cursor: "pointer",
          }}
        >
          <AddIcon style={{ color: "white" }} size={24} />
          <span style={{ color: "white" }}>Add Bank Detail</span>
        </div>
      </div>
    </div>

    {addBankDetail && (
      <>
        <div className={classes.inputCont}>
          <FormBox label="Beneficiary Name">
            <TextField
              value={beneficiaryName}
              onChange={(e) => setBeneficiaryName(e.target.value)}
              placeholder="Enter Beneficiary Name"
              error={!!errors.beneficiaryName}
              helperText={errors.beneficiaryName}
              fullWidth={true}
            />
          </FormBox>
          <FormBox label="Bank Name">
            <TextField
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              placeholder="Enter Bank Name"
              error={!!errors.bankName}
              helperText={errors.bankName}
              fullWidth={true}
            />
          </FormBox>
          <FormBox label="Account Number">
            <TextField
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              placeholder="Enter Account Number"
              error={!!errors.accountNumber}
              helperText={errors.accountNumber}
              fullWidth={true}
            />
          </FormBox>
          <FormBox label="IFSC">
            <TextField
              value={ifsc}
              onChange={(e) => setIfsc(e.target.value)}
              placeholder="Enter IFSC"
              error={!!errors.ifsc}
              helperText={errors.ifsc}
              fullWidth={true}
            />
          </FormBox>
          <FormBox label="Branch Name">
            <TextField
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
              placeholder="Enter Branch Name"
              error={!!errors.branchName}
              helperText={errors.branchName}
              fullWidth={true}
            />
          </FormBox>
        </div>
        <div
          style={{
            width: "95%",
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <span
            onClick={() => (bankDetailEditId ? updateBankDetail() : createBankDetail())}
            className={classes.button}
            style={{
              height: "2.5rem",
      width: "7rem",
      borderRadius: "0.2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
      background: "#2d76e0",
              cursor: createBankDetailLoad || updateBankDetailLoad ? "not-allowed" : "pointer",
            }}
          >
           {bankDetailEditId ? "Update" : "Add"}
{createBankDetailLoad || updateBankDetailLoad ? (
  <div
    style={{
      height: "2.5rem",
      width: "7rem",
      borderRadius: "0.2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
      background: "#2d76e0",
    }}
  >
    <CircularProgress size={20} sx={{ color: "white" }} />
  </div>
) : null}
          </span>
          <span
            onClick={handleClearEdit}
            className={classes.button}
            style={{  height: "2.5rem",
              width: "7rem",
              borderRadius: "0.2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#2d76e0",
              border: " 1px solid #2d76e0", }}
          >
            Cancel
          </span>
        </div>
      </>
    )}

    {/* <div className={classes.bankDetailsCardCont}>
      {bankDetails.map((detail) => (
        <Card
          key={detail?._id}
          style={{
            width: "100%",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
              <span>Beneficiary Name: </span>
              <span>{detail?.beneficiaryName}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
              <span>Bank Name: </span>
              <span>{detail?.bankName}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
              <span>Account Number: </span>
              <span>{detail?.accountNumber}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
              <span>Branch Name: </span>
              <span>{detail?.branchName}</span>
            </div>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "end" }}>
            <ModeEditOutlineIcon
              onClick={() => handleSetEditBankDetail(detail)}
              sx={{ cursor: "pointer" }}
            />
            <DeleteOutlineIcon
              onClick={() => deleteBankDetail(detail?._id)}
              sx={{ cursor: "pointer" }}
            />
          </CardActions>
        </Card>
      ))}
    </div> */}

    <div className={classes.allCardCont}>
     


      {isAddingAccount && (
        <div className={classes.loadingIndicator}>
          <CircularProgress />
        </div>
      )}

      {loading ? (
        <>
          <PlaidBankSkeleton />
          <PlaidBankSkeleton />
          <PlaidBankSkeleton />
        </>
      ) : (
        <>
          {plaidBankAccounts?.length > 0 ? (
            plaidBankAccounts.map((bankAcct, index) => {
              return bankAcct?.accountType === "PlaidBankAccount" ? (
                <PlaidBankCard
                  bankAccount={bankAcct.account}
                  givenNickName={bankAcct?.nickName}
                  accountId={bankAcct?._id}
                  index={index}
                  key={index}
                  walletId={organization?.wallet}
                  plaidBankAccounts={plaidBankAccounts}
                  setPlaidBankAccounts={setPlaidBankAccounts}
                />
              ) : bankAcct?.accountType === "IndianBank" ? (
                <IndianBankCard bankAccount={bankAcct} key={index} />
              ) : null;
            })
          ) : (
            <div className={classes.emptyCont}>
              {/* <img src={emptyIcon} alt="No bank accounts" /> */}
              <p>
                No bank account exist. Click on the above card to add bank account
              </p>
            </div>
          )}
        </>
      )}
    </div>
  </div>
);
};

export default BankEdit;

