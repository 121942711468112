import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import CategoryTree from "./CategoryTree";
import SvgIcon from "@material-ui/core/SvgIcon";
import { deleteCostCodeCategory, getCategoriesByLibrary } from "./Api.call";
import TreeView from "@material-ui/lab/TreeView";
import {
  Button,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import CategoryCreateDrawer from "./Category.Crearte.Drawer";
import OfferingsDrawer from "./Offerings.Drawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { useDebounce } from "react-use";
import { Category } from "@material-ui/icons";
import { CATEGORY_TYPE } from "../../helpers/constants";
import ImportLibraryDrawer from "./ImportLibrary.Drawer";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  tableHead: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#E7ECFD",
    fontSize: "0.875rem",
    fontWeight: "500",
    padding: "0px 25px",
    "& p": {
      fontSize: "15px",
    },
  },
  categoiresCont: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
  },
  treeCont: {
    width: "100%",
  },
  searchAndAddBtnCont: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
    justifyContent: "space-between",
  },
  deleteConfirm: {
    width: "350px",
    padding: "25px 0px 35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiSvgIcon-root": {
      fontSize: "75px",
      color: "red",
    },
    "& h3": {
      fontSize: "20px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
}));

const CategoriesByLibrary = ({
  setSanckBarBool,
  setResponseStatusAndMsg,
  libraryId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { profileId } = useParams();

  const [loader, setLoader] = useState(true);
  const [changeState, setChangeState] = useState(true);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [linerCategoriesArr, setLinerCategoriesArr] = useState([]);
  const [categoriesIdArr, setCategoriesIdArr] = useState([]);
  const [createCategoryDawer, setCreateCategoryDrawer] = useState(false);
  const [openOfferingDrawer, setOpenOfferingDrawer] = useState(false);
  const [givenParentCategory, setGivenParentCategory] = useState(null);
  const [categoryDeleteLoader, setCategoryDeleteLoader] = useState(false);
  const [categoryForEditDelete, setCategoryForEditDelete] = useState(null);
  const [categoryDeleteConfirmBox, setCategoryDeleteConfirmBox] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [searchStr, setSearchStr] = useState("");
  const [filters, setFilters] = useState({
    category: "all",
  });
  const [openImportDrawer, setOpenImportDrawer] = useState(false);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const buildHierarchy = (items, parent = null) => {
    const result = [];
    for (const item of items) {
      if (item.parent == parent) {
        const children = buildHierarchy(items, item._id);
        if (children.length > 0) {
          item.children = children;
        }
        result.push(item);
      }
    }
    return result;
  };

  const getAllCategoriesByLibraries = async () => {
    setLoader(true);
    await getCategoriesByLibrary({
      librayId: libraryId,
      type: filters.category === "all" ? null : filters.category,
    })
      .then((data) => {
        console.log(data);
        const hierarchyCategory = buildHierarchy(data);
        setCategoriesArr(hierarchyCategory);
        setLinerCategoriesArr(data);
        setCategoriesIdArr(data.map((s) => s?._id));
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useDebounce(
    () => {
      getAllCategoriesByLibraries();
    },
    2000,
    [changeState, filters.category]
  );

  const openOfferingsDrawer = (category) => {
    setSelectedCategory(category);
    setOpenOfferingDrawer(true);
  };

  const categoryAction = (type, category) => {
    setCreateCategoryDrawer(false);
    setGivenParentCategory(null);
    setCategoryForEditDelete(null);
    setCategoryDeleteConfirmBox(false);
    switch (type) {
      case "create":
        setCreateCategoryDrawer(true);
        break;
      case "createWithParent":
        setCreateCategoryDrawer(true);
        setGivenParentCategory(category);
        break;
      case "edit":
        setCreateCategoryDrawer(true);
        setCategoryForEditDelete(category);
        break;
      case "delete":
        setCategoryDeleteConfirmBox(true);
        setCategoryForEditDelete(category);
        break;
    }
  };

  const afterCategoryCreateOrEdit = async (data, isEdit) => {
    if (isEdit) {
      setChangeState(!changeState);
      setResponseStatusAndMsg({
        status: "success",
        message: `catrgory modified successfully`,
      });
      setSanckBarBool(true);
    } else {
      setChangeState(!changeState);
      setResponseStatusAndMsg({
        status: "success",
        message: `catrgory successfully added`,
      });
      setSanckBarBool(true);
    }
    setGivenParentCategory(null);
    setCategoryForEditDelete(null);
  };

  const deleteCategory = async () => {
    setCategoryDeleteLoader(true);
    await deleteCostCodeCategory({
      categoryId: categoryForEditDelete?._id,
    })
      .then((data) => {
        setResponseStatusAndMsg({
          status: "success",
          message: "Category successfully deleted",
        });
      })
      .catch((err) => {
        console.log(err);
        setResponseStatusAndMsg({
          status: "error",
          message: "Something went wrong",
        });
      });
    setCategoryDeleteLoader(false);
    setCategoryDeleteConfirmBox(false);
    setCategoryForEditDelete(null);
    setSanckBarBool(true);
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.searchAndAddBtnCont}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          placeholder={"Enter category name"}
          autoComplete="off"
          value={filters.search}
          style={{ width: "350px" }}
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              search: e.target.value,
            }));
            setSearchStr(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <FormControl sx={{ minWidth: 200, flex: 1 }}>
            <Select
              name="category"
              value={filters.category || ""}
              onChange={handleFilterChange}
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "5px 10px",
                  fontSize: "0.875rem",
                },
                "& .MuiSelect-select": {
                  minHeight: "20px",
                },
              }}
            >
              <MenuItem key="all" value="all">
                All Categories
              </MenuItem>
              {CATEGORY_TYPE.map((category) => (
                <MenuItem
                  key={category}
                  value={category}
                  sx={{
                    padding: "5px 10px",
                    fontSize: "0.875rem",
                  }}
                >
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenImportDrawer(true)}
          >
            Import Library
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setCategoryForEditDelete(null);
              categoryAction("create", null);
            }}
          >
            New
          </Button>
        </Box>
      </div>
      <div style={{ width: "100%", height: "5px" }}>
        {loader && <LinearProgress />}
      </div>
      <div className={classes.tableHead}>
        <p>Category Name</p>
        <p>Action</p>
      </div>
      <div className={classes.categoiresCont}>
        <TreeView
          className={classes.treeCont}
          expanded={categoriesIdArr}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          {categoriesArr &&
            categoriesArr.map((category, i) => (
              <CategoryTree
                category={category}
                key={i}
                searchStr={searchStr}
                categoryAction={categoryAction}
                openOfferingsDrawer={openOfferingsDrawer}
              />
            ))}
        </TreeView>
      </div>
      <CategoryCreateDrawer
        createCategoryDawer={createCategoryDawer}
        setCreateCategoryDrawer={setCreateCategoryDrawer}
        profileId={profileId}
        categoryType="Common"
        afterCategoryCreateOrEdit={afterCategoryCreateOrEdit}
        libraryId={libraryId}
        givenParentCategory={givenParentCategory}
        categoryForEditDelete={categoryForEditDelete}
      />
      <OfferingsDrawer
        openOfferingDrawer={openOfferingDrawer}
        setOpenOfferingDrawer={setOpenOfferingDrawer}
        selectedCategory={selectedCategory}
        profileId={profileId}
        allCategories={linerCategoriesArr}
      />
      <DeleteConfirmBox
        open={categoryDeleteConfirmBox}
        setOpen={setCategoryDeleteConfirmBox}
        loading={categoryDeleteLoader}
        pageTitle={`Delete ${categoryForEditDelete?.name}`}
        cantent={
          <div className={classes.deleteConfirm}>
            <ReportProblemIcon />
            <h3>Are you sure?</h3>
            <p>You won't be able to revert this!</p>
          </div>
        }
        cancleConfirmDelete={() => {
          setCategoryDeleteConfirmBox(false);
        }}
        confirmDelete={deleteCategory}
      />
      <ImportLibraryDrawer
        open={openImportDrawer}
        setOpen={setOpenImportDrawer}
        libraryId={libraryId}
        profileId={profileId}
        afterImport={() => {
          setChangeState(!changeState);
          setResponseStatusAndMsg({
            status: "success",
            message: "Library imported successfully",
          });
          setSanckBarBool(true);
        }}
      />
    </div>
  );
};

export default CategoriesByLibrary;
