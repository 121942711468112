const { default: Api } = require("../../../../helpers/Api");

export const createTx = async (obj) => {
  try {
    const billRes = await Api.post("transaction/create", obj);
    const data = billRes?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createTxTemplate = async (obj) => {
  try {
    const templateRes = await Api.post("txtemplate/create", obj);
    const data = templateRes?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getProfileByWallet = async (obj) => {
  try {
    const templateRes = await Api.post("profile/get/by/wallet", obj);
    const data = templateRes?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createCertificateApiCall = async (obj) => {
  try {
    const templateRes = await Api.post("certificate/create", obj);
    const data = templateRes?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getTxCountByWallet = async (obj) => {
  try {
    const allTxs = await Api.post("transaction/count/get/by-wallet", obj);
    const txArr = allTxs?.data;
    return txArr;
  } catch (error) {
    return [];
  }
};

export const getTxCountByWalletBySpecificSecondParty = async (obj) => {
  try {
    const allTxs = await Api.post("transaction/get/tx/count/for-specific/second-party", obj);
    const txArr = allTxs?.data;
    return txArr;
  } catch (error) {
    return [];
  }
};

export const getBothSideTxCountByWallet = async (obj) => {
  try {
    const allTxs = await Api.post(
      "transaction/count/both-side/get/by-wallet",
      obj
    );
    const txArr = allTxs?.data;
    return txArr;
  } catch (error) {
    return [];
  }
};

export const getTxByWallet = async (obj) => {
  try {
    const allTxs = await Api.post("transaction/bywalletget", obj);
    const txArr = allTxs?.data;
    return txArr;
  } catch (error) {
    return [];
  }
};

export const getTxByWalletBySpecificSecondParty = async (obj) => {
  try {
    const allTxs = await Api.post("transaction/get/tx/for-specific/second-party", obj);
    const txArr = allTxs?.data;
    return txArr;
  } catch (error) {
    return [];
  }
};

export const createFileDocs = async (obj) => {
  try {
    const updatedData = await Api.post("doc/create", obj);
    const getData = updatedData?.data;
    return getData;
  } catch (error) {
    console.log(error);
  }
};

export const deleteFileDocs = async (obj) => {
  try {
    const updatedData = await Api.post("doc/delete", obj);
    const getData = updatedData?.data;
    return getData;
  } catch (error) {
    console.log(error);
  }
};

export const updateDeleteFlagForManyFiles = async (obj) => {
  try {
    const updatedData = await Api.post("user/updateFileDelete/many", obj);
    return updatedData;
  } catch (error) {
    console.log(error);
  }
};

export const getBothSideTxByWallet = async (obj) => {
  try {
    const allTxs = await Api.post("transaction/bywalletget/bothside", obj);
    const txArr = allTxs?.data;
    return txArr;
  } catch (error) {
    return [];
  }
};

export const getTxTemplateCountByWallet = async (obj) => {
  try {
    const allTemplateTxs = await Api.post(
      "txtemplate/get/by-wallet/count",
      obj
    );
    const txTemplateArr = allTemplateTxs?.data;
    return txTemplateArr;
  } catch (error) {
    return [];
  }
};

export const getTxTemplateByWallet = async (obj) => {
  try {
    const allTemplateTxs = await Api.post("txtemplate/bywalletget", obj);
    const txTemplateArr = allTemplateTxs?.data;
    return txTemplateArr;
  } catch (error) {
    return [];
  }
};

export const constrcutArrayOfBillNo = async (txs, typeNo) => {
  try {
    const newArr = await txs.map((tx) => {
      if (tx?.[typeNo]) {
        return tx?.[typeNo];
      }
    });
    return newArr;
  } catch (error) {
    return [];
  }
};

export const updateTx = async (obj) => {
  try {
    const billRes = await Api.post("transaction/update", obj);
    const data = billRes?.data;
    return data;
  } catch (error) {
    return null;
  }
};

export const updateTxTemplate = async (obj) => {
  try {
    const temlateRes = await Api.post("txtemplate/update", obj);
    const data = temlateRes?.data;
    return data;
  } catch (error) {
    return null;
  }
};

export const updateTxTemplateScheduleData = async (obj) => {
  try {
    const temlateRes = await Api.post("txtemplate/updateScheduleData", obj);
    const data = temlateRes?.data;
    return data;
  } catch (error) {
    return null;
  }
};

export const deleteTx = async (obj) => {
  try {
    const billRes = await Api.post("transaction/delete", obj);
    const data = billRes?.data;
    return billRes;
  } catch (error) {
    return null;
  }
};

export const deleteTxTemplate = async (obj) => {
  try {
    const billRes = await Api.post("txtemplate/delete", obj);
    const data = billRes?.data;
    return billRes;
  } catch (error) {
    return null;
  }
};

export const getTxByTemplateId = async (obj) => {
  try {
    const data = await Api.post("transaction/bytemplate", obj);
    const allTxs = data?.data;
    return allTxs;
  } catch (error) {
    return null;
  }
};

export const createJournalEntryandLine = async (obj) => {
  try {
    const resData = await Api.post("wallet/journal/entry/create", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const getDiscountOrTaxes = async (obj) => {
  try {
    const resData = await Api.post("discountortax/relation/get", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const invoiceInitialSubmit = async (obj) => {
  try {
    const resData = await Api.post("wallet/invoice/initial/submit", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const billInitialSubmit = async (obj) => {
  try {
    const resData = await Api.post("wallet/bill/initial/submit", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const editSubmittedInvoice = async (obj) => {
  try {
    const resData = await Api.post("wallet/invoice/submitted/edit", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const submitMultipleTransaction = async (obj) => {
  try {
    const resData = await Api.post("transaction/submit/multiple-tx", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const editSubmittedBill = async (obj) => {
  try {
    const resData = await Api.post("wallet/bill/submitted/edit", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const markInvoiceAsPaid = async (obj) => {
  try {
    const resData = await Api.post("wallet/invoice/initial/markaspaid", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const markBillAsPaid = async (obj) => {
  try {
    const resData = await Api.post("/wallet/bill/initial/markaspaid", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const markSubmittedInvoiceAsPaid = async (obj) => {
  try {
    const resData = await Api.post("wallet/invoice/submitted/markaspaid", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const markSubmittedBillAsPaid = async (obj) => {
  try {
    const resData = await Api.post("wallet/bill/submitted/markaspaid", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const getUserByName = async (obj) => {
  try {
    const resData = await Api.post("search/users", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const getOrgByName = async (obj) => {
  try {
    const resData = await Api.post("search/org", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const getOwnUsers = async (obj) => {
  try {
    const resData = await Api.post("wallet/relation/get/all", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const getAccessibleProject = async (obj) => {
  try {
    const resData = await Api.post("project/accessible/get", obj);
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    return null;
  }
};

export const findAndAddRelation = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post("wallet/relation/findOrAdd", obj);
      const checkData = checkRes?.data;
      return checkData;
    }
  } catch (error) {
    return null;
  }
};

export const projectUpdateForTx = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post("project/update/for/transaction", obj);
      const checkData = checkRes?.data;
      return checkData;
    }
  } catch (error) {
    return null;
  }
};

export const orgUpdateForTx = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post(
        "organization/update/for/transaction",
        obj
      );
      const checkData = checkRes?.data;
      return checkData;
    }
  } catch (error) {
    return null;
  }
};

export const userUpdateForTx = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post("user/update/for/transaction", obj);
      const checkData = checkRes?.data;
      return checkData;
    }
  } catch (error) {
    return null;
  }
};

export const walletUpdateForTx = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post("wallet/update", obj);
      const checkData = checkRes?.data;
      return checkData;
    }
  } catch (error) {
    return null;
  }
};

export const getTermForWallet = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post("wallet/term/get", obj);
      const checkData = checkRes?.data;
      return checkData;
    }
  } catch (error) {
    return null;
  }
};

export const createTermForWallet = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post("wallet/term/create", obj);
      const checkData = checkRes?.data;
      return checkData;
    }
  } catch (error) {
    return null;
  }
};

export const getFullTxData = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post("transaction/get/all-data", obj);
      return checkRes;
    }
  } catch (error) {
    return null;
  }
};

export const docFileRemoveAndUpdate = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post("doc/remove-file/update-doc", obj);
      return checkRes?.data;
    }
  } catch (error) {
    return null;
  }
};

export const docFileRemoveAndDelete = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post("doc/remove-file/delete-doc", obj);
      return checkRes?.data;
    }
  } catch (error) {
    return null;
  }
};

export const getDwollaBankAccountsAndStripeAccount = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post(
        "get/dwolla-bank-accounts/stripe-account",
        obj
      );
      return checkRes?.data;
    }
  } catch (error) {
    return null;
  }
};

export const getTxCountInMutual = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post(
        "get/transactions/count/mutual/by-wallet",
        obj
      );
      return checkRes?.data;
    }
  } catch (error) {
    return null;
  }
};

export const getTxsInMututal = async (obj) => {
  try {
    if (obj) {
      const checkRes = await Api.post("get/transactions/mutual/by-wallet", obj);
      return checkRes?.data;
    }
  } catch (error) {
    return null;
  }
};

export const updateTxWithPopulatedData = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("transaction/update/with-populatedData", obj);
      return res?.data;
    }
  } catch (error) {
    return null;
  }
};

export const createOfferingRelationWithOffering = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("create/offering-relation/with-offering", obj);
      return res?.data || null;
    }
  } catch (error) {
    return null;
  }
};

export const getBillListByIdWithItems = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/billList/", obj);
      return res?.data || null;
    }
  } catch (error) {
    return null;
  }
};

export const addBillItemNew = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/add/item/new", obj);
      return res?.data || null;
    }
  } catch (error) {
    return null;
  }
};

export const getAllTaxsByWallet = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/get/multiple-taxs", obj);
      return res?.data || [];
    }
  } catch (error) {
    return null;
  }
};

export const updateBillItemWithoutPopulate = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post(
        "wallet/billList/update/item/without-populate",
        obj
      );
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const getProcurementByType = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("procurement/get/workorder/for-dropdown", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const getPopulatedWalletById = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/get/populated-data", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const updateBillItemApiCall = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/billList/update/item", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const addBillIteminBillList = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/billList/add/item", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const getPopulatedCertificateById = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("get/certificate/by-id", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const getPopulatedWalletByIdForCertificate = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("get/wallet/populated/by-id", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const deleteBillItemById = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/billList/delete/item", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const getPopulatedTxTemplate = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("txTemplate/get", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const handleTxReject = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("transaction/handle/txReject", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const deleteMultipleBillItems = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/delete/bill/items", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const getOrCreateFinancialRelation = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/relation/contact/get-or-create", obj);
      return res?.data || {};
    }
  } catch (error) {
    return null;
  }
};

export const getBillingAccountByWalletId = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/billing/billing-account/sharedIds/get", obj);
      return res?.billingAccounts || [];
    }
  } catch (error) {
    return null;
  }
};

export const getBillingAccountByContact = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/billing/billing-account/get/by-contact", obj);
      return res?.data || [];
    }
  } catch (error) {
    return null;
  }
};

export const getTransactionAnalysisData = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("transaction/get/tx/analysis/data", obj);
      return res?.data || [];
    }
  } catch (error) {
    return null;
  }
};

export const getContactDataByContactId = async (obj) => {
  try {
    if (obj) {
      const res = await Api.post("wallet/relation/contact/get/by-contactId", obj);
      return res?.data || [];
    }
  } catch (error) {
    return null;
  }
};