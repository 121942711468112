import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Autocomplete, TextField } from "@mui/material";
import { getBlockAndUnitsByProject } from "../../api.call";
import { useDebounce } from "react-use";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputBox: {
    width: "100%",
    marginBottom: "15px",
    "& p": {
      fontSize: "15x",
      fontWeight: "500"
    }
  }
}));

const BlockUnitViewer = ({
  projectId, selectedBlocks, setSelectedBlocks,
  setSelectedUnits, selectedUnits
}) => {
  const classes = useStyles();

  const [blocks, setBlocks] = useState([]);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);

  const getBlocks = async () => {
    try {
      if (projectId && projectId !== "EMPTY") {
        setLoading(true);
        await getBlockAndUnitsByProject({ projectId })
          .then((data) => {
            console.log(data);
            setUnits(data?.rentalUnits);
            setBlocks(data?.projectBlocks);
          })
          .catch((error) => {
            console.log(error);
            setSelectedBlocks([]);
            setSelectedUnits([]);
            setBlocks([]);
            setUnits([]);
          })
          .finally(() => {
            setLoading(false);
          })
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (projectId) {
       getBlocks();
    }
  }, [projectId]);

  return (
    <>
      {projectId ? (
        <div>
          <div className={classes.inputBox}>
            <p>Block(s)</p>
            <Autocomplete
              options={blocks.filter(
                (block) =>
                  !selectedBlocks.map((sb) => sb._id).includes(block._id)
              )}
              value={selectedBlocks}
              onChange={(event, value) => {
                setSelectedBlocks(value);
              }}
              fullWidth
              multiple={true}
              size="small"
              getOptionLabel={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  {...params}
                  placeholder={"Select block"}
                />
              )}
            />
          </div>
          <div className={classes.inputBox}>
            <p>Unit(s)</p>
            <Autocomplete
              options={units.filter(
                (unit) => !selectedUnits.map((su) => su._id).includes(unit._id)
              )}
              value={selectedUnits}
              multiple={true}
              onChange={(event, value) => {
                setSelectedUnits(value);
                let unitBlocksIds = value.map((s) => s?.projectBlock);
                if (unitBlocksIds && unitBlocksIds.length > 0) {
                  let alredayBlockSelectedIds = selectedBlocks.map(
                    (s) => s?._id
                  );
                  let notPresentInSelectedBlock = [];
                  unitBlocksIds.map((s) => {
                    if (!alredayBlockSelectedIds.includes(s)) {
                      notPresentInSelectedBlock.push(s);
                    }
                  });
                  let haveToAddBlocks = [];
                  notPresentInSelectedBlock.map((s) => {
                    haveToAddBlocks.push(blocks.filter((b) => b._id == s)[0]);
                  });
                  console.log(haveToAddBlocks);
                  console.log(selectedBlocks);
                  setSelectedBlocks([...selectedBlocks, ...haveToAddBlocks]);
                }
              }}
              fullWidth
              size="small"
              getOptionLabel={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  {...params}
                  placeholder={"Select unit"}
                />
              )}
            />
          </div>
        </div>
      ) : (
        <p>Please select project first to link block(s) or unit(s)</p>
      )}
    </>
  );
};

export default BlockUnitViewer;