import React, { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  DialogActions,
  FormGroup,
} from "@mui/material";
import { Add, Close, ContentCopy, ExpandMore } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../helpers/Api";
import { Divider } from "@material-ui/core";
import allUnitStatuses from "../../../../helpers/allUnitStatuses";
import {
  DEFAULT_AREAS,
  DEFAULT_SALEABLE_AREAS,
} from "../../../../helpers/constants";

export const unitTypes = [
  "1BHK",
  "2BHK",
  "3BHK",
  "4BHK",
  "5BHK",
  "6BHK",
  "7BHK",
];

const CreateProjectBlockDrawer = ({
  open,
  setOpen,
  projectId,
  setProjectBlocks,
  setRentalUnitCount,
  projectBlocks,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);

  const [blockName, setBlockName] = useState("");
  const [floorNumber, setFloorNumber] = useState(0);
  const [description, setDescription] = useState("");
  const [floorData, setFloorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [baseRatePerSqft, setBaseRatePerSqft] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFloors, setSelectedFloors] = useState([]);
  const [currentSourceFloorIndex, setCurrentSourceFloorIndex] = useState(null);
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const [selectedBlockId, setSelectedBlockId] = useState("");

  const resetForm = () => {
    setBlockName("");
    setFloorNumber(0);
    setDescription("");
    setFloorData([]);
    setBaseRatePerSqft(0);
  };

  useEffect(() => {
    resetForm();
  }, [open]);

  useEffect(() => {
    createFloorsAndUnits();
  }, [floorNumber]);

  const createFloorsAndUnits = () => {
    if (floorNumber <= 0) return;
    let newAry = [];
    for (let i = 0; i <= floorNumber; i++) {
      newAry.push({
        floor: i,
        units: [
          {
            unitType: "2BHK",
            unitValue: 0,
            area: 0,
            saleableArea: 0,
          },
        ],
      });
    }
    setFloorData(newAry);
  };

  const handleAddUnit = (floorIndex) => {
    const newFloorData = [...floorData];
    newFloorData[floorIndex]?.units?.push({
      unitType: "2BHK",
      unitValue: 0,
    });
    setFloorData(newFloorData);
  };

  const handleUnitTypeChange = (floorIndex, unitIndex, value) => {
    const newFloorData = [...floorData];
    if (newFloorData[floorIndex]?.units?.[unitIndex]) {
      newFloorData[floorIndex].units[unitIndex].unitType = value;
    }
    setFloorData(newFloorData);
  };

  const handleUnitValueChange = (floorIndex, unitIndex, value) => {
    const newFloorData = [...floorData];
    if (newFloorData[floorIndex]?.units?.[unitIndex]) {
      const unitCount = parseInt(value) || 0;
      const floor = newFloorData[floorIndex].floor;
      const unitType = newFloorData[floorIndex].units[unitIndex].unitType;

      newFloorData[floorIndex].units[unitIndex].unitValue = unitCount;
      newFloorData[floorIndex].units[unitIndex].individualUnits = Array(
        unitCount
      )
        .fill(null)
        .map((_, i) => ({
          unitName: `${floor + 1}0${i + 1} - ${unitType}`,
          area: DEFAULT_AREAS[unitType] || 0,
          saleableArea: DEFAULT_SALEABLE_AREAS[unitType] || 0,
          status: [
            {
              activityType: "Availability",
              activityStatus: "Available",
            },
            {
              activityType: "Ownership",
              activityStatus: "Developer / Project owner",
            },
          ],
        }));
    }
    setFloorData(newFloorData);
  };

  const handleIndividualUnitChange = (
    floorIndex,
    unitIndex,
    unitArrayIndex,
    field,
    value
  ) => {
    const newFloorData = [...floorData];
    if (
      newFloorData[floorIndex]?.units?.[unitIndex]?.individualUnits?.[
        unitArrayIndex
      ]
    ) {
      newFloorData[floorIndex].units[unitIndex].individualUnits[unitArrayIndex][
        field
      ] = value;
    }
    setFloorData(newFloorData);
  };

  const handleCopyToFloorsBelow = (sourceFloorIndex) => {
    const newFloorData = [...floorData];
    const sourceFloorUnits = [...newFloorData[sourceFloorIndex].units];

    for (let i = sourceFloorIndex + 1; i < newFloorData.length; i++) {
      newFloorData[i].units = sourceFloorUnits.map((unit, unitIndex) => {
        const copiedUnit = { ...unit };

        if (copiedUnit.individualUnits) {
          copiedUnit.individualUnits = unit.individualUnits.map(
            (individualUnit, index) => ({
              ...individualUnit,
              unitName: `${i + 1}0${index + 1} - ${unit.unitType}`,
              status: [...individualUnit.status],
            })
          );
        }

        return copiedUnit;
      });
    }

    setFloorData(newFloorData);
  };

  const handleCopyToSpecificFloors = (selectedFloors) => {
    const newFloorData = [...floorData];
    const sourceFloorUnits = [...newFloorData[currentSourceFloorIndex].units];

    selectedFloors.forEach((floorIndex) => {
      newFloorData[floorIndex].units = sourceFloorUnits.map(
        (unit, unitIndex) => {
          const copiedUnit = { ...unit };

          if (copiedUnit.individualUnits) {
            copiedUnit.individualUnits = unit.individualUnits.map(
              (individualUnit, index) => ({
                ...individualUnit,
                unitName: `${floorIndex + 1}0${index + 1} - ${unit.unitType}`,
                status: [...individualUnit.status],
              })
            );
          }

          return copiedUnit;
        }
      );
    });

    setFloorData(newFloorData);
  };

  const createProjectBlockAndUnits = async () => {
    try {
      setLoading(true);
      if (floorNumber <= 0) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please add at least one floor",
          },
        });
        setLoading(false);
        return;
      }

      const { projectBlocks, rentalUnits } = await Api.post(
        "/public/project/block/create",
        {
          projectId: projectId,
          userId: user?._id,
          name: blockName,
          description: description,
          rentalUnits: floorData,
          isRental: true,
          floors: floorNumber,
          baseRatePerSqft: baseRatePerSqft,
        }
      );

      if (projectBlocks && rentalUnits) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Project Block Created Successfully",
          },
        });
        setProjectBlocks(projectBlocks);
        setRentalUnitCount(rentalUnits?.length);
        setOpen(false);
      }
    } catch (error) {
      console.error("Error creating project block:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to Create Project Block",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCopyUnitsFromAnotherBlock = () => {
    setCopyDialogOpen(true);
  };

  const copyUnitsFromBlock = async (block) => {
    try {
      setLoadingUnits(true);
      setBaseRatePerSqft(block?.baseRatePerSqft || 0);
      setBlockName(block?.name || "");
      setFloorNumber(block?.totalFloors || 0);

      const { data } = await Api.post("/project/unit/getUnitsByBlock", {
        blockId: block?._id,
      });

      if (data) {
        // Group units by floor and unit type
        const unitsByFloor = data.reduce((acc, unit) => {
          if (!acc[unit.floor]) {
            acc[unit.floor] = {};
          }
          if (!acc[unit.floor][unit.unitType]) {
            acc[unit.floor][unit.unitType] = [];
          }
          acc[unit.floor][unit.unitType].push(unit);
          return acc;
        }, {});

        // Transform into required format
        const transformedData = Object.keys(unitsByFloor).map((floorNum) => {
          const floorUnitTypes = unitsByFloor[floorNum];
          return {
            floor: parseInt(floorNum),
            units: Object.entries(floorUnitTypes).map(([unitType, units]) => ({
              unitType,
              unitValue: units.length,
              area: units[0]?.area || 0,
              saleableArea: units[0]?.saleableArea || 0,
              individualUnits: units.map((unit) => ({
                unitName: unit.name,
                area: unit.area,
                saleableArea: unit.saleableArea,
                status: unit.status.map((s) => ({
                  activityType: s.activityType,
                  activityStatus: Array.isArray(s.activityStatus)
                    ? s.activityStatus[0]
                    : s.activityStatus,
                })),
              })),
            })),
          };
        });

        // Sort by floor number and ensure all floors exist
        const maxFloor = Math.max(...Object.keys(unitsByFloor).map(Number));
        const finalData = Array.from({ length: maxFloor + 1 }, (_, index) => {
          const existingFloor = transformedData.find((f) => f.floor === index);
          if (existingFloor) return existingFloor;
          return {
            floor: index,
            units: [
              {
                unitType: "2BHK",
                unitValue: 0,
                area: 0,
                saleableArea: 0,
              },
            ],
          };
        });

        setFloorData(finalData);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: { success: false, message: "Unable to fetch units" },
      });
    } finally {
      setLoadingUnits(false);
      setCopyDialogOpen(false);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <Box sx={{ width: 900, p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={0}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">Add Block</Typography>
            <Button
              variant="outlined"
              onClick={handleCopyUnitsFromAnotherBlock}
              sx={{ mr: 1 }}
            >
              Copy Units from Another Block
            </Button>
          </Box>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <TextField
          fullWidth
          label="Block Name"
          value={blockName}
          onChange={(e) => setBlockName(e.target.value)}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Total Floor"
          type="number"
          value={floorNumber}
          onChange={(e) => setFloorNumber(parseInt(e.target.value))}
          margin="normal"
          inputProps={{ min: 0 }}
        />

        <TextField
          fullWidth
          label="Base Rate Per Sqft (₹)"
          type="number"
          value={baseRatePerSqft}
          onChange={(e) => setBaseRatePerSqft(parseInt(e.target.value))}
          margin="normal"
          inputProps={{ min: 0 }}
        />

        {floorData?.map((floor, floorIndex) => (
          <Box
            key={floorIndex}
            mt={2}
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              p: 2,
              backgroundColor: "#f8f8f8",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  color: "#2c3e50",
                }}
              >
                Floor {floor?.floor}
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                {floorIndex < floorData.length - 1 && (
                  <Button
                    size="small"
                    startIcon={<ContentCopy />}
                    onClick={() => handleCopyToFloorsBelow(floorIndex)}
                    sx={{
                      borderColor: "primary.main",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        color: "white",
                      },
                    }}
                  >
                    Copy to Floors Below
                  </Button>
                )}
                <Button
                  size="small"
                  startIcon={<ContentCopy />}
                  onClick={() => {
                    setCurrentSourceFloorIndex(floorIndex);
                    setDialogOpen(true);
                  }}
                  sx={{
                    borderColor: "secondary.main",
                    "&:hover": {
                      backgroundColor: "secondary.light",
                      color: "white",
                    },
                  }}
                >
                  Copy to Particular Floors
                </Button>
              </Box>
            </Box>

            {floor?.units?.map((unit, unitIndex) => (
              <Box
                key={unitIndex}
                sx={{
                  mb: 2,
                  ml: 2,
                  p: 2,
                  border: "1px solid #e8e8e8",
                  borderRadius: 1,
                  backgroundColor: "white",
                }}
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      value={unit?.unitType}
                      onChange={(e) =>
                        handleUnitTypeChange(
                          floorIndex,
                          unitIndex,
                          e.target.value
                        )
                      }
                    >
                      {unitTypes?.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Number of Units"
                      inputProps={{ min: 0 }}
                      value={unit?.unitValue}
                      onChange={(e) =>
                        handleUnitValueChange(
                          floorIndex,
                          unitIndex,
                          Math.max(0, e.target.value)
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => handleAddUnit(floorIndex)}
                      sx={{
                        border: "1px solid rgba(0,0,0,0.5)",
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>

                {unit.individualUnits?.length > 0 && (
                  <Box sx={{ ml: 3, mt: 2 }}>
                    <Accordion
                      sx={{
                        "&:before": {
                          display: "none",
                        },
                        boxShadow: "none",
                        border: "1px solid #e0e0e0",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        sx={{
                          backgroundColor: "#f5f5f5",
                          "&:hover": {
                            backgroundColor: "#eeeeee",
                          },
                        }}
                      >
                        <Typography color="primary">
                          Individual Units ({unit.individualUnits.length})
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          {unit.individualUnits.map(
                            (individualUnit, unitArrayIndex) => (
                              <Grid
                                item
                                xs={12}
                                key={unitArrayIndex}
                                sx={{
                                  p: 1,
                                  "&:not(:last-child)": {
                                    borderBottom: "1px solid #f0f0f0",
                                  },
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{ mb: 1, color: "text.secondary" }}
                                >
                                  Unit {unitArrayIndex + 1}
                                </Typography>
                                <Grid container spacing={2}>
                                  <Grid item xs={3}>
                                    <TextField
                                      fullWidth
                                      label={`Unit ${unitArrayIndex + 1} Name`}
                                      value={individualUnit.unitName}
                                      onChange={(e) =>
                                        handleIndividualUnitChange(
                                          floorIndex,
                                          unitIndex,
                                          unitArrayIndex,
                                          "unitName",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      label="Area (sq ft)"
                                      value={individualUnit.area}
                                      onChange={(e) =>
                                        handleIndividualUnitChange(
                                          floorIndex,
                                          unitIndex,
                                          unitArrayIndex,
                                          "area",
                                          parseFloat(e.target.value)
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      label="Saleable Area (sq ft)"
                                      value={individualUnit.saleableArea}
                                      onChange={(e) =>
                                        handleIndividualUnitChange(
                                          floorIndex,
                                          unitIndex,
                                          unitArrayIndex,
                                          "saleableArea",
                                          parseFloat(e.target.value)
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <FormControl fullWidth>
                                      <InputLabel>Availability</InputLabel>
                                      <Select
                                        value={
                                          individualUnit.status?.find(
                                            (s) =>
                                              s.activityType === "Availability"
                                          )?.activityStatus || "Available"
                                        }
                                        onChange={(e) => {
                                          const newStatus =
                                            individualUnit.status?.map((s) =>
                                              s.activityType === "Availability"
                                                ? {
                                                    ...s,
                                                    activityStatus:
                                                      e.target.value,
                                                  }
                                                : s
                                            ) || [];
                                          handleIndividualUnitChange(
                                            floorIndex,
                                            unitIndex,
                                            unitArrayIndex,
                                            "status",
                                            newStatus
                                          );
                                        }}
                                        label="Availability"
                                      >
                                        {allUnitStatuses.Availability.map(
                                          (status) => (
                                            <MenuItem
                                              key={status}
                                              value={status}
                                            >
                                              {status}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <FormControl fullWidth>
                                      <InputLabel>Ownership</InputLabel>
                                      <Select
                                        value={
                                          individualUnit.status?.find(
                                            (s) =>
                                              s.activityType === "Ownership"
                                          )?.activityStatus ||
                                          "Developer / Project owner"
                                        }
                                        onChange={(e) => {
                                          const newStatus =
                                            individualUnit.status?.map((s) =>
                                              s.activityType === "Ownership"
                                                ? {
                                                    ...s,
                                                    activityStatus:
                                                      e.target.value,
                                                  }
                                                : s
                                            ) || [];
                                          handleIndividualUnitChange(
                                            floorIndex,
                                            unitIndex,
                                            unitArrayIndex,
                                            "status",
                                            newStatus
                                          );
                                        }}
                                        label="Ownership"
                                      >
                                        {allUnitStatuses.Ownership.map(
                                          (status) => (
                                            <MenuItem
                                              key={status}
                                              value={status}
                                            >
                                              {status}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        ))}

        <TextField
          fullWidth
          label="Description"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="normal"
          inputProps={{ maxLength: 200 }}
          helperText={`${description?.length}/200`}
        />

        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            sx={{ mr: 1 }}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={createProjectBlockAndUnits}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Add"}
          </Button>
        </Box>
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Select Floors</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              {floorData.map((_, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedFloors.includes(index)}
                      onChange={(e) => {
                        const newSelectedFloors = e.target.checked
                          ? [...selectedFloors, index]
                          : selectedFloors.filter((floor) => floor !== index);
                        setSelectedFloors(newSelectedFloors);
                      }}
                    />
                  }
                  label={`Floor ${index}`}
                />
              ))}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleCopyToSpecificFloors(selectedFloors);
              setDialogOpen(false);
            }}
            color="primary"
          >
            Copy
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={copyDialogOpen} onClose={() => setCopyDialogOpen(false)}>
        <DialogTitle>Select Block to Copy Units From</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Select Block</InputLabel>
            <Select
              value={selectedBlockId}
              onChange={(e) => setSelectedBlockId(e.target.value)}
              label="Select Block"
            >
              {projectBlocks.map((block) => (
                <MenuItem key={block._id} value={block._id}>
                  {block.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCopyDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              const selectedBlock = projectBlocks.find(
                (block) => block._id === selectedBlockId
              );
              if (selectedBlock) {
                copyUnitsFromBlock(selectedBlock);
              }
            }}
            color="primary"
            disabled={!selectedBlockId || loadingUnits}
          >
            {loadingUnits ? <CircularProgress size={24} /> : "Copy"}
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
};

export default CreateProjectBlockDrawer;
