import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Api from "../../../../helpers/Api";
import FormBox from "../../../styled/generic/FormBox";
import { unitTypes } from "./CreateProjectBlockDrawer";
import allUnitStatuses from "../../../../helpers/allUnitStatuses";

const CreateUnitDialog = ({
  open,
  onClose,
  block,
  projectId,
  getProjectBlocks,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    floor: 0,
    name: "",
    unitType: "1BHK",
    availability: "Available",
    ownership: "Developer / Project owner",
    area: 0,
    saleableArea: 0,
  });

  async function handleCreate() {
    setLoading(true);
    const payload = {
      ...formData,
      projectBlock: block._id,
      blockId: block._id,
      project: projectId,
    };

    const data = await Api.post("/project/unit/add-unit", payload);
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: { success: true, message: "Unit created successfully" },
      });
      getProjectBlocks();
      onClose();
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: { success: false, message: "Failed to create unit" },
      });
    }
    setLoading(false);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create Unit</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <FormBox label="Select Floor" sx={{ mb: 2 }}>
          <Select
            variant="outlined"
            value={formData?.floor}
            onChange={(e) => {
              setFormData({ ...formData, floor: e.target.value });
            }}
            fullWidth
            defaultValue={block?.totalFloors - 1}
          >
            {Array(block?.totalFloors)
              .fill("")
              .map((item, index) => (
                <MenuItem key={index} value={index}>
                  Floor {index}
                </MenuItem>
              ))}
          </Select>
        </FormBox>

        <FormBox label="Unit Name" sx={{ mb: 2 }}>
          <TextField
            autoFocus
            value={formData?.name}
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
            }}
            type="text"
            fullWidth
          />
        </FormBox>

        <FormBox label="Area (sq ft)" sx={{ mb: 2 }}>
          <TextField
            value={formData?.area}
            onChange={(e) => {
              setFormData({ ...formData, area: Number(e.target.value) });
            }}
            type="number"
            fullWidth
            inputProps={{ min: 0 }}
          />
        </FormBox>

        <FormBox label="Saleable Area (sq ft)" sx={{ mb: 2 }}>
          <TextField
            value={formData?.saleableArea}
            onChange={(e) => {
              setFormData({ ...formData, saleableArea: e.target.value });
            }}
            type="number"
            fullWidth
            inputProps={{ min: 0 }}
          />
        </FormBox>

        <FormBox label="Unit Type" sx={{ mb: 2 }}>
          <Select
            value={formData?.unitType}
            onChange={(e) => {
              setFormData({ ...formData, unitType: e.target.value });
            }}
            fullWidth
            variant="outlined"
          >
            {unitTypes?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormBox>

        <FormBox label="Availability" sx={{ mb: 2 }}>
          <Select
            value={formData?.availability}
            onChange={(e) => {
              setFormData({ ...formData, availability: e.target.value });
            }}
            fullWidth
            variant="outlined"
          >
            {allUnitStatuses.Availability.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormBox>

        <FormBox label="Ownership" sx={{ mb: 2 }}>
          <Select
            value={formData?.ownership}
            onChange={(e) => {
              setFormData({ ...formData, ownership: e.target.value });
            }}
            fullWidth
            variant="outlined"
          >
            {allUnitStatuses.Ownership.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          variant="contained"
          disabled={loading || !formData.name}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUnitDialog;
