import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import { useEffect, useState } from "react";
import { getOrgAnalytics, getProjectAnalytics } from "./api.call";
import {
  FormControl,
  Select,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import OrgAnalyticsCharts from "./OrgAnalyticsCharts";

export default function OrgAnalytics({ profile, projectId }) {
  const [data, setData] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [isOrgAnalytics, setIsOrgAnalytics] = useState(false);

  const fetchAnalytics = async () => {
    let analyticsData;

    if (projectId) {
      // Fetch project analytics
      analyticsData = await getProjectAnalytics({
        projectId,
        blockIds: selectedBlocks,
      });
    } else {
      // Fetch organization analytics
      analyticsData = await getOrgAnalytics({
        orgId: profile?.parent?._id,
      });
      setIsOrgAnalytics(true);
    }

    setData(analyticsData);
  };

  console.log("analytics", data);

  useEffect(() => {
    fetchAnalytics();
  }, [profile, projectId]);

  useEffect(() => {
    if (data.length > 0) {
      const allProjectIds = data.map((d) => d.project._id);
      setSelectedProjects(allProjectIds);

      const allBlockIds = data.flatMap((p) => p.analytics.map((b) => b._id));
      setSelectedBlocks(allBlockIds);
    }
  }, [data]);

  const handleProjectChange = (event) => {
    const value = event.target.value;
    setSelectedProjects(value);

    const newBlockIds = data
      .filter((p) => value.includes(p.project._id))
      .flatMap((p) => p.analytics.map((b) => b._id));
    setSelectedBlocks(newBlockIds);
  };

  const handleBlockChange = (event) => {
    setSelectedBlocks(event.target.value);
  };

  return (
    <StandardAppContainerRounded>
      <Box
        mb={3}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        sx={{ gap: 40 }}
      >
        {isOrgAnalytics && (
          <FormControl
            variant="outlined"
            style={{ minWidth: 200, marginBottom: 16 }}
          >
            <Select
              multiple
              value={selectedProjects}
              onChange={handleProjectChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) return <em>Select Projects</em>;
                return `${selected.length} Project${
                  selected.length > 1 ? "s" : ""
                } Selected`;
              }}
            >
              {data.map((d) => (
                <MenuItem key={d.project._id} value={d.project._id}>
                  <Checkbox
                    checked={selectedProjects.includes(d.project._id)}
                  />
                  <ListItemText primary={d.project.displayName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <Select
            multiple
            value={selectedBlocks}
            onChange={handleBlockChange}
            displayEmpty
            disabled={selectedProjects.length === 0}
            renderValue={(selected) => {
              if (selected.length === 0) return <em>Select Blocks</em>;
              return `${selected.length} Block${
                selected.length > 1 ? "s" : ""
              } Selected`;
            }}
          >
            {selectedProjects.length > 0 &&
              data
                .filter((p) => selectedProjects.includes(p.project._id))
                .flatMap((p) => p.analytics)
                .map((block) => (
                  <MenuItem key={block._id} value={block._id}>
                    <Checkbox checked={selectedBlocks.includes(block._id)} />
                    <ListItemText
                      primary={`${block.blockName} (${block.totalUnits} Units)`}
                      secondary={`Available: ${block.totalAvailable}, Sold: ${block.totalSold}`}
                    />
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Box>

      {selectedBlocks.length > 0 && (
        <OrgAnalyticsCharts
          data={data}
          selectedProjects={selectedProjects}
          selectedBlocks={selectedBlocks}
        />
      )}
    </StandardAppContainerRounded>
  );
}
