import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Button,
  Divider,
  Chip,
  Paper,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import Api from "../../../../helpers/Api";
import FormBox from "../../../styled/generic/FormBox";
import allUnitStatuses from "../../../../helpers/allUnitStatuses";
import _ from "lodash";
import {
  Construction,
  Sell,
  Home,
  CheckCircle,
  Add,
  Clear,
  FilterList,
  Apartment,
  MonetizationOn,
} from "@mui/icons-material";

const getProjectBlocks = async (projectId) => {
  const { data } = await Api.post("/project/unit/getUnitsByProject", {
    projectId,
  });

  let resUnits = data?.units || [];
  let blockUnits = _.groupBy(resUnits, "projectBlock");
  return {
    blocks: data?.blocks || [],
    units: blockUnits || [],
  };
};

const BlockUnitBulkActionDrawer = ({
  open,
  onClose,
  projectId,
  allowedActions = [],
  blockIds = [],
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedAction, setSelectedAction] = useState("Construction");
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [selectedSingleUnit, setSelectedSingleUnit] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedUnitIds, setSelectedUnitIds] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [blocks, setBlocks] = useState([]);
  const [units, setUnits] = useState([]);
  const [allUnits, setAllUnits] = useState({});
  const [baseRateValue, setBaseRateValue] = useState(0);

  const actionConfig = {
    // Construction: {
    //   icon: <Construction fontSize="small" />,
    //   color: theme.palette.warning.main,
    //   lightColor: theme.palette.warning.lighter,
    //   label: '🏗️ Construction'
    // },
    Listing: {
      icon: <Sell fontSize="small" />,
      color: theme.palette.success.main,
      lightColor: theme.palette.success.lighter,
      label: "📋 Listing",
    },
    Rent: {
      icon: <Home fontSize="small" />,
      color: theme.palette.info.main,
      lightColor: theme.palette.info.lighter,
      label: "🏠 Rent",
    },
    Availability: {
      icon: <CheckCircle fontSize="small" />,
      color: theme.palette.primary.main,
      lightColor: theme.palette.primary.lighter,
      label: "✅ Availability",
    },
    Ownership: {
      icon: <Apartment fontSize="small" />,
      color: theme.palette.secondary.main,
      lightColor: theme.palette.secondary.lighter,
      label: "👥 Ownership",
    },
    Sale: {
      icon: <MonetizationOn fontSize="small" />,
      color: theme.palette.error.main,
      lightColor: theme.palette.error.lighter,
      label: "💰 Sale",
    },
  };

  useEffect(() => {
    if (open) {
      getProjectBlocks(projectId).then((item) => {
        setAllUnits(item.units);
        setBlocks(item.blocks);
      });
    }
  }, [open]);

  useEffect(() => {
    if (selectedBlock) {
      setUnits(allUnits[selectedBlock]);
      setSelectedUnitIds([]);
    }
  }, [selectedBlock]);

  const handleActionChange = (event) => {
    setSelectedAction(event.target.value);
    if (!allUnitStatuses.multiStatusAllowed.includes(event.target.value)) {
      setSelectedUnit([]);
    }
  };

  const handleUnitChange = (event) => {
    const value = event.target.value;
    if (allUnitStatuses.multiStatusAllowed.includes(selectedAction)) {
      setSelectedUnit(value);
    } else {
      setSelectedSingleUnit(value);
    }
  };

  const handleSave = async () => {
    const payload = {
      unitIds: selectedUnitIds,
      statuses: selectedStatus,
      baseRate: baseRateValue ? Number(baseRateValue) : undefined,
      blockId: selectedBlock,
    };
    const data = await Api.post("/project/unit/bulk-update", payload);
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: { success: true, message: "Units updated successfully." },
      });
      onClose();
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An error occurred during update.",
        },
      });
    }
  };

  const handleSelectAllUnits = () => {
    const allUnitIds = units?.map((unit) => unit._id) || [];
    setSelectedUnitIds(allUnitIds);
  };

  const handleClearAllUnits = () => {
    setSelectedUnitIds([]);
  };

  const filteredBlocks = blocks.filter(
    (block) => blockIds.length === 0 || blockIds.includes(block._id)
  );

  const availableActions = Object.keys(actionConfig).filter(
    (action) => allowedActions.length === 0 || allowedActions.includes(action)
  );

  const isActionAlreadySelected = (action) => {
    if (allUnitStatuses.multiStatusAllowed.includes(action)) {
      return false; // Always allow adding for multi-status actions
    }
    return selectedStatus.some((status) => status.activityType === action);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: "500px", maxWidth: "100vw", p: 3 },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: theme.palette.text.primary,
            fontSize: "1.25rem",
          }}
        >
          Update Unit Status
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      <Box sx={{ mb: 4 }}>
        <FormBox label="Select Block" sx={{ mb: 2 }}>
          <Select
            fullWidth
            size="small"
            value={selectedBlock || ""}
            onChange={(e) => setSelectedBlock(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.grey[300],
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.main,
              },
              "& .MuiSelect-select": {
                padding: "8px 14px",
              },
              boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 250,
                  "& .MuiMenuItem-root": {
                    padding: "8px 14px",
                  },
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              Select a block
            </MenuItem>
            {filteredBlocks.map((block) => (
              <MenuItem value={block._id} key={block._id}>
                {block.name}
              </MenuItem>
            ))}
          </Select>
        </FormBox>

        <FormBox label="Select Units">
          <Box sx={{ mb: 1, display: "flex", gap: 1 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleSelectAllUnits}
              disabled={!selectedBlock || units.length === 0}
              startIcon={<FilterList fontSize="small" />}
              sx={{
                fontSize: "0.75rem",
                py: 0.5,
                borderColor: theme.palette.grey[300],
                color: theme.palette.text.secondary,
                "&:hover": {
                  borderColor: theme.palette.primary.main,
                  bgcolor: "transparent",
                },
              }}
            >
              Select All Units
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleClearAllUnits}
              disabled={selectedUnitIds.length === 0}
              startIcon={<Clear fontSize="small" />}
              sx={{
                fontSize: "0.75rem",
                py: 0.5,
                borderColor: theme.palette.grey[300],
                color: theme.palette.text.secondary,
                "&:hover": {
                  borderColor: theme.palette.error.main,
                  color: theme.palette.error.main,
                  bgcolor: "transparent",
                },
              }}
            >
              Clear Selection
            </Button>
          </Box>
          <Select
            fullWidth
            size="small"
            multiple
            value={selectedUnitIds}
            onChange={(event) => setSelectedUnitIds(event.target.value)}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.grey[300],
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.main,
              },
              "& .MuiSelect-select": {
                padding: "8px 14px",
                maxHeight: "120px",
                overflow: "auto",
              },
              boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
            }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.length > 0 && (
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    {selected.length} units selected
                  </Typography>
                )}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300,
                  "& .MuiMenuItem-root": {
                    padding: "4px 14px",
                    "&.Mui-selected": {
                      bgcolor: theme.palette.primary.lighter,
                    },
                    "&.Mui-selected:hover": {
                      bgcolor: theme.palette.primary.light,
                    },
                  },
                },
              },
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                bgcolor: "background.paper",
                zIndex: 1,
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                p: 1,
              }}
            >
              <Typography
                variant="caption"
                sx={{ color: theme.palette.text.secondary }}
              >
                {units.length} units available • {selectedUnitIds.length}{" "}
                selected
              </Typography>
            </Box>
            {units.map((unit) => (
              <MenuItem
                key={unit._id}
                value={unit._id}
                sx={{
                  "&:hover": {
                    bgcolor: theme.palette.action.hover,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2">{unit.name}</Typography>
                  {unit.currentStatus && (
                    <Chip
                      label={unit.currentStatus}
                      size="small"
                      sx={{
                        height: "16px",
                        fontSize: "0.65rem",
                        bgcolor: theme.palette.grey[100],
                        color: theme.palette.text.secondary,
                      }}
                    />
                  )}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormBox>
      </Box>

      <Paper
        elevation={0}
        sx={{
          mb: 4,
          p: 2,
          bgcolor: theme.palette.grey[50],
          border: `1px solid ${theme.palette.grey[200]}`,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            mb: 1.5,
            fontWeight: 600,
            color: theme.palette.text.primary,
          }}
        >
          Selected: {selectedUnitIds.length} unit(s)
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            maxHeight: "120px",
            overflow: "auto",
            p: 1,
            bgcolor: "background.paper",
            borderRadius: 1,
            border: `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          {selectedUnitIds.map((id) => {
            const unit = allUnits[selectedBlock]?.find(
              (item) => item._id === id
            );
            return (
              <Chip
                key={id}
                label={unit?.name}
                size="small"
                onDelete={() => {
                  setSelectedUnitIds((prev) =>
                    prev.filter((unitId) => unitId !== id)
                  );
                }}
                sx={{
                  bgcolor: theme.palette.primary.lighter,
                  color: theme.palette.primary.main,
                  "& .MuiChip-deleteIcon": {
                    color: theme.palette.primary.main,
                    "&:hover": {
                      color: theme.palette.primary.dark,
                    },
                  },
                }}
              />
            );
          })}
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 3,
        }}
      >
        <Select
          size="small"
          value={selectedAction}
          onChange={handleActionChange}
          sx={{ width: "150px" }}
        >
          {availableActions.map((action) => (
            <MenuItem value={action} key={action}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {actionConfig[action].icon}
                <Typography variant="body2">
                  {actionConfig[action].label}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>

        {allUnitStatuses.multiStatusAllowed.includes(selectedAction) ? (
          <Select
            size="small"
            multiple
            value={selectedUnit}
            onChange={handleUnitChange}
            sx={{ width: "200px" }}
            renderValue={(selected) => selected.join(", ")}
          >
            {allUnitStatuses[selectedAction]?.map((unit) => (
              <MenuItem value={unit} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Select
            size="small"
            value={selectedSingleUnit}
            onChange={handleUnitChange}
            sx={{ width: "200px" }}
          >
            {allUnitStatuses[selectedAction]?.map((unit) => (
              <MenuItem value={unit} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
        )}

        <Button
          variant="contained"
          size="small"
          onClick={() => {
            const isMultiStatus =
              allUnitStatuses.multiStatusAllowed.includes(selectedAction);
            const statusValue = isMultiStatus
              ? selectedUnit
              : [selectedSingleUnit];

            const newStatuses = statusValue.filter(Boolean).map((status) => ({
              activityType: selectedAction,
              activityStatus: status,
            }));

            if (isMultiStatus) {
              setSelectedStatus((prev) => [...prev, ...newStatuses]);
              setSelectedUnit([]);
            } else {
              setSelectedStatus((prev) => [
                ...prev.filter((s) => s.activityType !== selectedAction),
                ...newStatuses,
              ]);
              setSelectedSingleUnit("");
            }
          }}
          disabled={
            (allUnitStatuses.multiStatusAllowed.includes(selectedAction)
              ? selectedUnit.length === 0
              : !selectedSingleUnit) || isActionAlreadySelected(selectedAction)
          }
          sx={{
            minWidth: "100px",
            bgcolor: theme.palette.primary.main,
            "&:hover": {
              bgcolor: theme.palette.primary.dark,
            },
          }}
        >
          Add
        </Button>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 1.5,
            fontWeight: 600,
            color: theme.palette.text.primary,
          }}
        >
          Selected Actions: {selectedStatus?.length}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 3 }}>
          {selectedStatus.map((status, index) => (
            <Chip
              key={index}
              icon={actionConfig[status.activityType].icon}
              label={`${status.activityStatus}`}
              onDelete={() => {
                setSelectedStatus((prev) => prev.filter((_, i) => i !== index));
              }}
              size="small"
              sx={{
                bgcolor: actionConfig[status.activityType].lightColor,
                color: actionConfig[status.activityType].color,
                "& .MuiChip-icon": {
                  color: actionConfig[status.activityType].color,
                },
                "& .MuiChip-deleteIcon": {
                  color: actionConfig[status.activityType].color,
                  "&:hover": {
                    color: theme.palette.error.main,
                  },
                },
                fontWeight: 500,
                borderRadius: 1,
              }}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 3,
          }}
        >
          <TextField
            size="small"
            type="number"
            fullWidth
            label="Change Base Rate of Block (₹/sqft)"
            value={baseRateValue}
            onChange={(e) => setBaseRateValue(e.target.value)}
            InputProps={{
              inputProps: { step: "any" },
              startAdornment: "₹",
            }}
            helperText="Enter positive value to increase or negative value to decrease"
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{ minWidth: "120px" }}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default BlockUnitBulkActionDrawer;
