import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  Chip,
  IconButton,
} from "@mui/material";
import {
  NavigateBefore as PrevIcon,
  NavigateNext as NextIcon,
  Home as HomeIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import Api from "../../helpers/Api";
import ProjectUnitBooking from "../ProjectUnit/Project.Unit.Booking";
import TaskCard from "../planning/tasks/components/TaskCard";
import TransactionsList from "../booking/booking/TransactionsList";

const ProjectUnitView = () => {
  const { unitId } = useParams();
  const [unit, setUnit] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedImage, setSelectedImage] = useState(0);
  const [loading, setLoading] = useState(true);

  // Placeholder images - replace with actual unit images
  const images = [
    "https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/1643383/pexels-photo-1643383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/2635038/pexels-photo-2635038.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/1571468/pexels-photo-1571468.jpeg?auto=compress&cs=tinysrgb&w=600",
  ];
  console.log("unit: ", unit);
  const statusConfig = {
    Listing: {
      color: "success",
      label: "📋 Listing",
    },
    Rent: {
      color: "info",
      label: "🏠 Rent",
    },
    Availability: {
      color: "primary",
      label: "✅ Availability",
    },
    Ownership: {
      color: "secondary",
      label: "👥 Ownership",
    },
    Sale: {
      color: "error",
      label: "💰 Sale",
    },
  };

  useEffect(() => {
    const fetchUnitDetails = async () => {
      try {
        const response = await Api.post("project/unit/get", { unitId });
        console.log("Unit details response: ", response.data);
        setUnit(response.data);
      } catch (error) {
        console.error("Error fetching unit details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUnitDetails();
  }, [unitId]);

  const handleImageNav = (direction) => {
    if (direction === "next") {
      setSelectedImage((prev) => (prev + 1) % images.length);
    } else {
      setSelectedImage((prev) => (prev - 1 + images.length) % images.length);
    }
  };

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle={unit?.name || "Unit Details"}
    >
      <Box sx={{ mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h5">
            {!loading &&
              (`${unit?.about?.name} - ${unit?.about?.projectBlock?.name}` ||
                "Unit Name")}
          </Typography>
          <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}>
            {unit?.about?.status.map((status, index) => {
              return (
                <Chip
                  key={index}
                  label={`${
                    statusConfig[status.activityType]?.label ||
                    status.activityType
                  }: ${status.activityStatus[0]}`}
                  color={statusConfig[status.activityType]?.color || "default"}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      <StandardAppContainerRounded>
        {/* Image Gallery */}
        <Box sx={{ position: "relative", mb: 3 }}>
          <Box
            sx={{
              height: 400,
              position: "relative",
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <img
              src={images[selectedImage]}
              alt={`Unit view ${selectedImage + 1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <IconButton
              sx={{
                position: "absolute",
                left: 16,
                top: "50%",
                transform: "translateY(-50%)",
                bgcolor: "background.paper",
              }}
              onClick={() => handleImageNav("prev")}
            >
              <PrevIcon />
            </IconButton>
            <IconButton
              sx={{
                position: "absolute",
                right: 16,
                top: "50%",
                transform: "translateY(-50%)",
                bgcolor: "background.paper",
              }}
              onClick={() => handleImageNav("next")}
            >
              <NextIcon />
            </IconButton>
          </Box>

          {/* Thumbnail Strip */}
          <Grid container spacing={1} sx={{ mt: 2 }}>
            {images.map((img, index) => (
              <Grid item key={index}>
                <Box
                  onClick={() => setSelectedImage(index)}
                  sx={{
                    width: 80,
                    height: 60,
                    borderRadius: 1,
                    overflow: "hidden",
                    cursor: "pointer",
                    border: selectedImage === index ? 2 : 0,
                    borderColor: "primary.main",
                  }}
                >
                  <img
                    src={img}
                    alt={`Thumbnail ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Unit Details */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {!loading &&
              (`${unit?.about?.name} - ${unit?.about?.projectBlock?.name}` ||
                "Unit Name")}
          </Typography>
          <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
            <Chip
              icon={<HomeIcon />}
              label={unit?.about?.unitType || "Unit Type"}
              color="primary"
            />
            <Chip
              label={`${unit?.about?.area || 0} sq ft`}
              variant="outlined"
            />
            <Chip
              label={`${unit?.about?.saleableArea || 0} sq ft (Saleable)`}
              variant="outlined"
            />
          </Box>
        </Box>

        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
          >
            <Tab label="About" />
            <Tab label="Bookings" />
            <Tab label="Tasks" />
            <Tab label="Transactions" />
            <Tab label="Tickets" />
          </Tabs>
        </Box>

        {/* Tab Content */}
        <Box sx={{ py: 3 }}>
          {activeTab === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Unit Details
                </Typography>
                {/* Add unit details content */}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Specifications
                </Typography>
                {/* Add specifications content */}
              </Grid>
            </Grid>
          )}
          {activeTab === 1 && <ProjectUnitBooking bookings={unit?.bookings} />}
          {activeTab === 2 && (
            <Box>
              <Grid container spacing={2}>
                {unit?.tasks?.length > 0 ? (
                  unit.tasks.map((task) => (
                    <Grid item xs={12} md={6} lg={4} key={task._id}>
                      <TaskCard
                        task={task}
                        tasks={unit.tasks}
                        setTasks={(newTasks) => {
                          setUnit((prev) => ({
                            ...prev,
                            tasks: newTasks,
                          }));
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      align="center"
                    >
                      No tasks found for this unit
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
          {activeTab === 3 && (
            <Box>
              <Grid container spacing={2}>
                {unit?.bookings?.length > 0 ? (
                  unit.bookings.map((booking) => (
                    <Grid item xs={12} key={booking._id}>
                      <Box sx={{ mb: 3 }}>
                        <TransactionsList
                          bookingId={booking._id}
                          profileId={booking?.project?.profile}
                          booking={booking}
                        />
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      align="center"
                    >
                      No bookings or transactions found for this unit
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
          {activeTab === 4 && <Typography>Tickets content</Typography>}
        </Box>
      </StandardAppContainerRounded>
    </StandardContainer>
  );
};

export default ProjectUnitView;
