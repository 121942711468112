import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { ButtonBase } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddIndianBankAccountDialog from "./AddIndianBankAccountDialog";

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: "430px",
    padding: "15px 20px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    [theme.breakpoints.only("xs")]: {
      width: "90%",
      padding: "10px 15px",
      borderRadius: "10px",
    },
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    "& img": {
      height: "30px",
    },
    "& h3": {
      fontSize: "25px",
      marginLeft: "10px",
    },
    [theme.breakpoints.only("xs")]: {
      "& img": {
        height: "25px",
      },
      "& h3": {
        fontSize: "20px",
        marginLeft: "10px",
      },
    },
  },
  bankText: {
    color: "#407bff",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Poppins', sans-serif`,
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      fontSize: "14px",
    },
  },
  paraText: {
    fontSize: "16px",
    color: "#263238",
    fontWeight: "400",
    fontFamily: `'Poppins', sans-serif`,
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      fontSize: "14px",
    },
  }
}));

export default function IndianLinkBtn({ walletId, onAccountAdded, setIsAddingAccount }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [showBankDialog, setShowBankDialog] = useState(false);

  return (
    <>
      <ButtonBase onClick={() => setShowBankDialog(true)}>
        <Paper className={classes.paperStyle}>
          <div className={classes.cardHeader}>
            <h3>Indian Bank</h3>
          </div>

          <p className={classes.bankText}>
            Click here to add an Indian bank account
          </p>
          <p className={classes.paraText}>
            Add an Indian bank for use in transactions, certificates, demand
            deeds etc
          </p>
        </Paper>
      </ButtonBase>
      <AddIndianBankAccountDialog
        walletId={walletId}
        showBankDialog={showBankDialog}
        setShowBankDialog={setShowBankDialog}
        onAccountAdded={onAccountAdded}
        setIsAddingAccount={setIsAddingAccount}
      />
    </>
  );
}