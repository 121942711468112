import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import Api from "../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { getPopulatedTeamDataByIdForProject } from "./api.call";
import FormBox from "../styled/generic/FormBox";
import { Box, Grid, Typography, Chip } from "@mui/material";
import HorizBox from "../styled/generic/HorizBox";
import { FolderOutlined, Home as HomeIcon } from "@material-ui/icons";
import TableContainer from "../styled/generic/TableContainer";
import dayjs from "dayjs";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import BlockUnitBulkActionDrawer from '../listing/common/drawers/BlockUnitBulkActionDrawer';
import CreateUnitDialog from '../listing/common/drawers/CreateUnitDialog';
import { Add as AddIcon } from '@mui/icons-material';
import _ from 'lodash';
import CreateProjectBlockDrawer from '../listing/common/drawers/CreateProjectBlockDrawer';
import { styled } from "@mui/material/styles";
import { Card, CardContent, CardActionArea } from "@mui/material";

const StyledCard = styled(Card)(({ theme, selected }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  border: selected ? `2px solid ${theme.palette.primary.main}` : "none",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[4],
  },
}));

const ProjectUnit = () => {
  const { teamId } = useParams();
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const [projectData, setProjectData] = useState(null);
  const [team, setTeam] = useState(null);
  const [projectBlocks, setProjectBlocks] = useState([]);
  const [rentalUnits, setRentalUnits] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [unitToEdit, setUnitToEdit] = useState(null);
  const [editUnitData, setEditUnitData] = useState({
    name: "",
    unitType: "",
    area: "",
    saleableArea: "",
  });
  const [isSaving, setIsSaving] = useState(false);
  const [openBulkActionDrawer, setOpenBulkActionDrawer] = useState(false);
  const [openCreateUnitDialog, setOpenCreateUnitDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openCreateBlockDrawer, setOpenCreateBlockDrawer] = useState(false);
  const [rentalUnitCount, setRentalUnitCount] = useState(0);

  const handleEditUnit = (unit) => {
    setUnitToEdit(unit);
    setEditUnitData({
      name: unit.name || "",
      unitType: unit.unitType || "",
      area: unit.area || "",
      saleableArea: unit.saleableArea || "",
    });
    setOpenEditDialog(true);
  };
  
  const handleSaveUnit = async () => {
    setIsSaving(true);
    try {
      const updatedUnit = { ...unitToEdit, ...editUnitData };

      // Make API call to update unit details on the server
      const response = await Api.post("project/unit/update", { rentalUnit: updatedUnit });
      setRentalUnits((prevUnits) =>
        prevUnits.map((unit) => (unit._id === updatedUnit._id ? updatedUnit : unit))
      );
      setOpenEditDialog(false);
      setUnitToEdit(null);
 
    } catch (error) {
      console.error("Error updating unit:", error);
      // Optionally, you can show an error message to the user here
    } finally {
      setIsSaving(false);
    }
  };

  const getProjectData = async () => {
    if (teamDictionary[teamId]) {
      const teamData = teamDictionary[teamId];
      setTeam(teamData);
      setProjectData(teamData?.parent);
    } else {
      await getPopulatedTeamDataByIdForProject({ teamId: teamId })
        .then((updatedTeam) => {
          setTeam(updatedTeam);
          setProjectData(updatedTeam?.parent);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getProjectBlocks = async () => {
    try {
      const { data } = await Api.post("/public/project/block/get", {
        projectId: projectData?._id,
        getRentalUnits: true,
      });

      if (data) {
        setProjectBlocks(data);
        if (data.length > 0) {
          setSelectedBlock(data[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjectData();
  }, []);

  useEffect(() => {
    if (projectData) {
      getProjectBlocks();
    }
  }, [projectData]);

  const getRentalUnitsByBlock = async (blockId) => {
    try {
      setLoadingUnits(true);
      const { data } = await Api.post("/project/unit/getUnitsByBlock", {
        blockId: blockId,
      });

      if (data) {
        console.log("units: ", data, ' blockId ', blockId);
        setRentalUnits(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUnits(false);
    }
  };

  useEffect(() => {
    if (selectedBlock) {
      getRentalUnitsByBlock(selectedBlock._id);
    }
  }, [selectedBlock]);

  const fetchBlocks = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/project/unit/getUnitsByProject", {
        projectId: projectData?._id,
      });

      let resUnits = data?.units || [];
      let blockUnits = _.groupBy(resUnits, "projectBlock");

      setProjectBlocks(data?.blocks || []);
      setRentalUnits(data?.units || []);
      setRentalUnitCount(data?.units?.length || 0);
      setSelectedBlock(data?.blocks[0] || null);
    } catch (error) {
      console.error("Error fetching blocks:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (projectData?._id) {
      fetchBlocks();
    }
  }, [projectData?._id]);

  return (
    <StandardContainer showAppBar={true} appBarTitle="Units">
      <StandardAppContainerRounded>
        <FormBox label={`Blocks (${projectBlocks?.length || 0})`}>
          <Box sx={{ 
            mb: 2,
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setOpenCreateBlockDrawer(true)}
            >
              New Block
            </Button>
          </Box>
          
          <Grid container spacing={2}>
            {projectBlocks?.map((block) => (
              <Grid item xs={12} sm={6} md={4} key={block._id}>
                <StyledCard selected={selectedBlock?._id === block?._id}>
                  <CardActionArea 
                    onClick={() => setSelectedBlock(block)}
                    sx={{ height: '100%' }}
                  >
                    <CardContent>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        mb: 2
                      }}>
                        <HomeIcon 
                          sx={{ 
                            fontSize: '2rem',
                            color: 'primary.main',
                            mr: 1.5
                          }} 
                        />
                        <Box>
                          <Typography 
                            variant="h6" 
                            sx={{ 
                              fontWeight: 600,
                              mb: 0.5
                            }}
                          >
                            {block?.name || "Untitled"}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                          >
                            Created: {new Date(block?.createdAt).toLocaleDateString()}
                          </Typography>
                        </Box>
                      </Box>
                      
                      <Box sx={{
                        display: 'flex',
                        gap: 1,
                        flexWrap: 'wrap'
                      }}>
                        <Chip
                          icon={<HomeIcon sx={{ fontSize: '1rem' }} />}
                          label={`${block?.unitCount || 0} Units`}
                          size="small"
                          color={selectedBlock?._id === block?._id ? "primary" : "default"}
                          sx={{ 
                            borderRadius: '8px',
                            '& .MuiChip-label': { px: 1 }
                          }}
                        />
                        <Chip
                          label={`${block?.totalFloors || 0} Floors`}
                          size="small"
                          variant="outlined"
                          sx={{ 
                            borderRadius: '8px',
                            '& .MuiChip-label': { px: 1 }
                          }}
                        />
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </FormBox>
      </StandardAppContainerRounded>
      <StandardAppContainerRounded>
        <FormBox label="Units">
          <Box sx={{ 
            mb: 2,
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Typography variant="subtitle1" color="text.secondary">
              {selectedBlock ? `${selectedBlock.name} - ${rentalUnits.length} Units` : 'Select a block to view units'}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setOpenBulkActionDrawer(true)}
              >
                Bulk Actions
              </Button>
              <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => setOpenCreateUnitDialog(true)}
                disabled={!selectedBlock}
              >
                Unit
              </Button>
            </Box>
          </Box>
          <TableContainer
            columns={["UNIT NAME", "CREATED DATE", "ACTIONS"]}
            data={rentalUnits}
            loading={loading}
          >
            {rentalUnits?.map((unit) => (
              <tr key={unit._id}>
                <td>{unit?.name || "Untitled"}</td>
                <td>{dayjs(unit?.createdAt).format("DD-MM-YYYY")}</td>
                <td>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleEditUnit(unit)}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </TableContainer>
        </FormBox>
      </StandardAppContainerRounded>

      <BlockUnitBulkActionDrawer
        open={openBulkActionDrawer}
        onClose={() => setOpenBulkActionDrawer(false)}
        projectId={projectData?._id}
        blockIds={selectedBlock ? [selectedBlock._id] : []}
      />

      <CreateUnitDialog
        open={openCreateUnitDialog}
        onClose={() => setOpenCreateUnitDialog(false)}
        block={selectedBlock}
        projectId={projectData?._id}
        getProjectBlocks={fetchBlocks}
      />

      <CreateProjectBlockDrawer
        open={openCreateBlockDrawer}
        setOpen={setOpenCreateBlockDrawer}
        projectId={projectData?._id}
        getProjectBlocks={fetchBlocks}
        setProjectBlocks={setProjectBlocks}
        setRentalUnitCount={setRentalUnitCount}
      />

      <Dialog
  open={openEditDialog}
  onClose={() => setOpenEditDialog(false)}
  fullWidth
  maxWidth="sm"
>
  <DialogTitle>Edit Unit Details</DialogTitle>
  <DialogContent>
    {/* Unit Name */}
    <TextField
      label="Unit Name"
      value={editUnitData.name}
      onChange={(e) =>
        setEditUnitData({ ...editUnitData, name: e.target.value })
      }
      fullWidth
      margin="normal"
    />

    {/* Unit Type */}
    <FormControl fullWidth margin="normal">
      <InputLabel>Unit Type</InputLabel>
      <Select
        value={editUnitData.unitType}
        onChange={(e) =>
          setEditUnitData({ ...editUnitData, unitType: e.target.value })
        }
        label="Unit Type"
      >
        <MenuItem value="1BHK">1BHK</MenuItem>
        <MenuItem value="2BHK">2BHK</MenuItem>
        <MenuItem value="3BHK">3BHK</MenuItem>
        <MenuItem value="4BHK">4BHK</MenuItem>
        <MenuItem value="5BHK">5BHK</MenuItem>
        <MenuItem value="6BHK">6BHK</MenuItem>
        <MenuItem value="7BHK">7BHK</MenuItem>
      </Select>
    </FormControl>

    {/* Area */}
    <TextField
      label="Area"
      type="number"
      value={editUnitData.area}
      onChange={(e) =>
        setEditUnitData({ ...editUnitData, area: e.target.value })
      }
      fullWidth
      margin="normal"
    />

    {/* Saleable Area */}
    <TextField
      label="Saleable Area"
      type="number"
      value={editUnitData.saleableArea}
      onChange={(e) =>
        setEditUnitData({ ...editUnitData, saleableArea: e.target.value })
      }
      fullWidth
      margin="normal"
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenEditDialog(false)} disabled={isSaving}>
      Cancel
    </Button>
    <Button
      onClick={handleSaveUnit}
      variant="contained"
      color="primary"
      disabled={isSaving}
    >
      {isSaving ? "Saving..." : "Save"}
    </Button>
  </DialogActions>
</Dialog>


    </StandardContainer>
  );
};

export default ProjectUnit;
